import React, { useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import Fade from 'react-reveal/Fade';

export default function QuestionListItem({question}) {
    const [showAnswer, setShowAnswerState] = useState(false);

    const toggleShowAnswerState = () => {
        showAnswer ? setShowAnswerState(false) : setShowAnswerState(true);
    };

    return (
        <div className="card mt-3">
            <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                    <span className="-tg-small-subtitle -smaller ww-break">{question.question}</span>
                    <div className="d-flex">
                        <button className="btn btn-transparent" onClick={() => {toggleShowAnswerState()}}><FontAwesomeIcon size="sm" icon={showAnswer ? faMinus : faPlus} className="ml-2" /></button>
                    </div>
                </div>

                <Fade>
                    {
                        showAnswer
                            ?   <span className="-tg-reg-text">{question.answer}</span>
                            :   ""
                    }
                </Fade>
            </div>
        </div>
    )
};