import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';

export default function Landing({user, webtext}) {

    const createMarkup = () => {
        return {__html: webtext.description}
    };

    return (
        <div>
            <div className="d-none d-lg-block spacer -sm"></div>

            <Fade top>
                <span className="-tg-title">{webtext.title}</span>
                <div dangerouslySetInnerHTML={createMarkup()}></div>
            </Fade>

            <Slide right>
                <div>
                    <Link to="/level" className="btn -round btn-secundary mt-2">To MTRL tool <FontAwesomeIcon className="ml-2" icon={faArrowRight} /></Link>
                </div>
            </Slide>

            <Slide left>
                <div className="mt-5">
                    <Link to="/background" className="btn -round btn-primary mt-5">Background <FontAwesomeIcon className="ml-2" icon={faArrowRight} /></Link>
                    <Link to="/how-to-use" className="btn -round btn-primary mt-5 ml-3">How to use <FontAwesomeIcon className="ml-2" icon={faArrowRight} /></Link>
                    <Link to="/FAQ" className="btn -round btn-primary mt-5 ml-3">FAQ <FontAwesomeIcon className="ml-2" icon={faArrowRight} /></Link>

                    {
                        user.isAdmin 
                            ?   <Link 
                                    to="/admin"
                                    className="btn -round btn-secundary mt-5 ml-3"
                                >
                                    CMS
                                    <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
                                </Link>
                            :   ""
                    }

                </div>
            </Slide>
        </div>
    )
};