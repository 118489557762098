import React, { useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import Highlighter from "./Highlighter";
import CriterionArrow from "./CriterionArrow";
import "./FrontendListItem.scss";
import Fade from 'react-reveal/Fade';

export default function CriterionListItem({thisRef, criterion, definitions, setCurrentSelectionState}) {
    const [showExample, setShowExampleState] = useState(false);
    
    const toggleExample = () => {
        showExample ? setShowExampleState(false) : setShowExampleState(true);
    };

    const ListOfArrows = () => {
        return (
            <>
                {
                    (criterion.hasOwnProperty("incomingCriteria") && criterion.incomingCriteria.length > 0 && criterion.incomingCriteria[0] !== null) || (criterion.hasOwnProperty("outgoingCriteria") && criterion.outgoingCriteria.length > 0 && criterion.outgoingCriteria[0] !== null)
                        ?   <div className="col-12 col-lg-4 arrow-col">
                                {
                                    criterion.hasOwnProperty("incomingCriteria") && criterion.incomingCriteria.length > 0 && criterion.incomingCriteria[0] !== null
                                        ?   criterion.incomingCriteria.map((relatedCriterion, key) => {
                                                return <CriterionArrow key={key} criterion={relatedCriterion} type="in" setCurrentSelectionState={setCurrentSelectionState} />
                                            })
                                        :   ""
                                }
                                {
                                    criterion.hasOwnProperty("outgoingCriteria") && criterion.outgoingCriteria.length > 0 && criterion.outgoingCriteria[0] !== null
                                        ?   criterion.outgoingCriteria.map((relatedCriterion, key) => {
                                                return <CriterionArrow key={key} criterion={relatedCriterion} type="out" setCurrentSelectionState={setCurrentSelectionState} />
                                            })
                                        :   ""
                                }
                            </div>
                        :   ""
                }
            </>
        );
    };

    return (
        <div ref={thisRef} className="row criterion-list-item">
            <div className="col -with-border d-flex flex-column justify-content-between">
                <div className="p-4">
                    <Highlighter className="-tg-small-subtitle" text={criterion.title} definitionsArray={definitions} />
                    <Highlighter className="-tg-reg-text" text={criterion.text} definitionsArray={definitions} />

                    {/* <p className="-tg-small-subtitle">{ criterion.title }</p>
                    <p className="-tg-reg-text">{ criterion.text }</p> */}

                        {
                            criterion.examples.length > 0 && criterion.examples[0] !== null && criterion.examples[0].text !== ""
                                ?   <>
                                        <span onClick={() => {toggleExample()}} className="-tg-link -turq my-3">Show example<FontAwesomeIcon size="sm" icon={showExample ? faMinus : faPlus} className="ml-2" /></span>
                                    
                                        {
                                            criterion.examples.map((example, key) => {
                                                return (
                                                    <Fade key={key}>
                                                        <div className={showExample ? "mt-2" : "mt-2 d-none"}>
                                                            <Highlighter className="-tg-reg-text" text={example.text} definitionsArray={definitions} />
                                                            {/* <p className="-tg-reg-text mb-0">{ example.text }</p> */}
                                                        </div>
                                                    </Fade>
                                                )
                                            })
                                        }
                                    </>
                                :   ""
                        }

                    {
                        criterion.templates.length > 0 && criterion.templates[0] !== null
                            ?   <>
                                    <span className="-tg-small-subtitle -smaller mt-3 mb-1">Downloads/Links</span>

                                    {
                                        criterion.templates.map((template, key) => {
                                            return <a key={key} className="-tg-link" target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_API_BASE_URL}/downloads?fileName=${template.file}`}>{template.title}<FontAwesomeIcon size="sm" icon={faExternalLinkAlt} className="ml-2" /></a>
                                        })
                                    }
                                </>
                            :   ""
                    }

                </div>
                <div className="px-4">
                    <div className="border"></div>
                </div>
            </div>

            <ListOfArrows />

        </div>
    );
};