import React from "react";
import Fade from 'react-reveal/Fade';

export default function WebtextListItem({webtext, setCurrentWebtextState}) {
    return (
        <Fade bottom>
            <div className="card mt-3">
                <div className="card-body">
                    <div className="d-flex justify-content-between">
                        <p><strong>{webtext.location}</strong></p>
                        <div className="d-flex">
                            <button className="btn btn-primary" onClick={() => {setCurrentWebtextState(webtext)}}>Edit</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fade>
    )
};