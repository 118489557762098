import React, { useState } from "react";
import SimpleNavBar from "../../components/Frontend/SimpleNavbar";
import QuestionListItem from "../../components/Frontend/QuestionListItem";
import Alert from 'react-bootstrap/Alert';
import Fade from 'react-reveal/Fade';

export default function FAQ({categories, questions}) {
    const [currentCategory, setCurrentCategoryState] = useState(categories[0]);

    const ListOfCategories = () => {
        if (categories.length < 2) {
            return false;
        } else {
            return (
                <div className="col-3">
                    <div className="d-flex flex-column mt-3">
                        {
                            categories.map((category, key) => {
                                return (
                                    <button key={key} onClick={() => {setCurrentCategoryState(category)}} className="btn btn-transparent text-align-start pl-0">{category.title}</button>
                                );
                            })
                        }
                    </div>
                </div>
            );
        }
    };

    const ListOfQuestions = () => {
        let arrayOfQuestions = [];
        categories.length > 1 && currentCategory !== undefined ? arrayOfQuestions = currentCategory.questions : arrayOfQuestions = questions;

        if (arrayOfQuestions !== undefined && arrayOfQuestions.length > 0) {
            return arrayOfQuestions.map((question, key) => {
                return (
                    <QuestionListItem key={key} question={question} />
                );
            });
        } else return <Alert className="mt-3" variant="primary">No items yet.</Alert>;
    };


    return (
        <>
            <SimpleNavBar />
            
            <div className="spacer -sm"></div>

            <Fade>
                <span className="-tg-title">FAQ</span>
            </Fade>

            <div className="row">

                {ListOfCategories ? <ListOfCategories /> : ""}

                <Fade bottom>
                    <div className="col">
                        <ListOfQuestions />
                    </div>
                </Fade>

            </div>
        </>
    )
};