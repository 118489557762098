import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';

import Loader from "./components/Loader.js";
import Landing from "./pages/Frontend/landing.js";
import Level from "./pages/Frontend/level.js";
import Theme from "./pages/Frontend/theme.js";
import FAQ from "./pages/Frontend/faq.js";
import Background from "./pages/Frontend/webtext-page.js";
import HowToUse from "./pages/Frontend/webtext-page.js";

import dataProvider from "./api/dataProvider";

export default function Frontend({user}) {
    const [themes, setThemesState] = useState(null);
    const [levels, setLevelsState] = useState(null);
    const [quadrants, setQuadrantsState] = useState(null);
    const [definitions, setDefinitionsState] = useState(null);
    const [webtexts, setWebtextsState] = useState(null);
    const [categories, setCategoriesState] = useState(null);
    const [questions, setQuestionsState] = useState(null);

    const [error, setErrorState] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    
    const getAllData = async () => {

        await Promise.all([
            dataProvider.get("/themes"),
            dataProvider.get("/levels"),
            dataProvider.get("/quadrants"),
            dataProvider.get("/definitions"),
            dataProvider.get("/webtexts"),
            dataProvider.get("/categories"),
            dataProvider.get("/questions")
        ]).then(([themes, levels, quadrants, definitions, webtexts, categories, questions]) => {
            setThemesState(themes.data);
            setLevelsState(levels.data);
            setQuadrantsState(quadrants.data);
            setDefinitionsState(definitions.data);
            setWebtextsState(webtexts.data);
            setCategoriesState(categories.data);
            setQuestionsState(questions.data);

            setErrorState(null);
            setIsLoading(false);
        }).catch(err => {
            setErrorState(err.message);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        getAllData();
    }, []);


    if (isLoading) return <Loader />;
    if (error) return <Alert className="mt-3" variant="danger">{error}</Alert>;
    return (
        <>
            <Routes>
                <Route
                    path="/"
                    element={
                        <Landing
                            user={user}
                            webtext={webtexts[1]}
                        />
                    }
                />
                <Route
                    path="/FAQ"
                    element={
                        <FAQ
                            categories={categories}
                            questions={questions}
                        />
                    }
                />
                <Route
                    path="/background"
                    element={
                        <Background
                            webtext={webtexts[0]}
                        />
                    }
                />
                <Route
                    path="/how-to-use"
                    element={
                        <HowToUse
                            webtext={webtexts[2]}
                        />
                    }
                />
                <Route
                    path="/level"
                    element={
                        <Level
                            levels={levels}
                            themes={themes}
                            quadrants={quadrants}
                            definitions={definitions}
                        />
                    }
                />
                <Route
                    path="/theme"
                    element={
                        <Theme
                            levels={levels}
                            themes={themes}
                            quadrants={quadrants}
                            definitions={definitions}
                        />
                    }
                />
            </Routes>
        </>
    )
}
