import React from "react";
import "./CriterionArrow.scss";

export default function CriterionArrow ({criterion, type, setCurrentSelectionState}) {
    return (
        <div onClick={() => {setCurrentSelectionState(criterion)}} className={type === "out" ? "d-flex criterion-arrow -out my-3 my-lg-2" : "d-flex flex-row-reverse criterion-arrow -in my-3 my-lg-2"}>
            <div className="arrow-end-triangle"></div>
            <div className="arrow-end"></div>
            <div className="pointer">
                <div className={type === "out" ? "ml-4 d-flex flex-column justify-content-between" : "mr-4 d-flex flex-column justify-content-between"}>
                    <div className="d-flex justify-content-between -tg-reg-text -small">
                        <span className="overflow-ellipsis">{criterion.level.title}</span>
                        <span className="overflow-ellipsis">{criterion.theme.quadrant.title}</span>
                    </div>
                    <span className="-tg-reg-text -small -lh-small overflow-ellipsis -two-lines">{criterion.title}</span>
                </div>
            </div>
            <div className="pointer-end"></div>
        </div>
    );
};