import React from "react";

import "./ThemeNavbar.scss";

export default function ThemeNavBar({currentQuadrant, currentTheme, setCurrentThemeState}) {

    const ThemeLinks = () => {
        return currentQuadrant.themes.map((theme, key) => {
            return (
                <div className="theme-navbar-item d-flex flex-grow-1" key={key}>
                    <button
                        className={`btn d-flex align-items-center justify-content-center flex-grow-1 ${currentTheme && currentTheme._id === theme._id ? "active" : ""}`}
                        onClick={() => {setCurrentThemeState(theme)}}
                    >
                        <span style={{borderColor:currentQuadrant.colorCode}}>{theme.title}</span>
                    </button>
                </div>
            )
        });
    };

    return (
        <nav className="theme-navbar" style={{borderColor:currentQuadrant.colorCode}}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-between overflow-x-scroll">
                            <ThemeLinks></ThemeLinks>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
};