import React from "react";

import Alert from 'react-bootstrap/Alert';

const UserErrorPage = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 my-3">
                    <Alert variant="danger">
                        <h1>Error validating access token.</h1>
                    </Alert>          
                </div>
            </div>
        </div>
    );
};

export default UserErrorPage;