import React from "react";
import { useForm } from "react-hook-form";
import "./Form.scss"
import dataHandler from "../../api/dataHandler";

export default function FormWithSelect({item, collection, getAllData, setMessageState, setHasErrorState, setErrorMessageState, hasSortingNumber, hasDescription, selectArray, setCurrentItemState, handleDelete, cleanHook}) {

    let defaultValues = {};
    collection === "questions" 
        ? defaultValues = {
            question: item.question, 
            answer: item.answer, 
            category: item.category
        } 
        : defaultValues = {
            title: item.title,
            description: item.description,
            sortingNumber: item.sortingNumber
        }

    const { register, handleSubmit } = useForm({
        defaultValues: defaultValues
    });

    const onSubmit = async data => {
        setMessageState(false);
        setHasErrorState(false);
        setErrorMessageState(false);
        
        if (item === "new") {
            try {
                await dataHandler.post(`/${collection}/create`, data);
                setMessageState("Item successfully created.")
                getAllData();
                setCurrentItemState(null);
            } catch (err) {
                setErrorMessageState(err.response.data.message);
                setHasErrorState(true);
            }            
        } else {
            data.item__id = item._id;

            try {
                await dataHandler.post(`/${collection}/update`, data);
                setMessageState("Item successfully updated.")
                getAllData();
                setCurrentItemState(null);
            } catch (err) {
                setErrorMessageState(err.message);
                setHasErrorState(true);
            }
        }

    };

    const selectInputs = () => {
        if (selectArray === null || selectArray[0].options === undefined || selectArray[0].options.length < 1) {
            return false;
        } else {
            return selectArray.map((select, key) => {
                let selectLabel = select.name;
                let selectName = select.name.toLowerCase();
                return (
                    <div key={key} className="form-field">
                        <select defaultValue={item[selectName] ? item[selectName]._id : ""} className="form-control" id={selectName} name={"select["+selectName+"]"} key={key} ref={register({required:true})}>
                            {
                                select.options.map((option, key) => {
                                    return <option value={option._id} key={key}>{option.title}</option>
                                })
                            }
                        </select>
                        <label htmlFor={selectName}>{selectLabel}</label>
                    </div>
                )
            });
        }
    };
     
    return (
      <form onSubmit={handleSubmit(onSubmit)}>

        {
            collection === "questions"
                ? (
                    <div className="form-field">
                        <input id="question" className="form-control" name="question" ref={register({required:true})} placeholder="Question" />
                        <label htmlFor="question">Question</label>
                    </div>
                )
                :   (
                    <div className="form-field">
                        <input id="title" className="form-control" name="title" ref={register({required:true})} placeholder="Title" />
                        <label htmlFor="title">Title</label>
                    </div>
                )
        }

        {
            collection === "questions"
                ? (
                    <div className="form-field">
                        <textarea id="answer" className="form-control" name="answer" ref={register({required:true})} placeholder="Answer"></textarea>
                        <label htmlFor="answer">Answer</label>
                    </div>
                )
                : 
                    hasDescription === false
                        ?   ""
                        :   <div className="form-field">
                                <textarea id="description" className="form-control" name="description" ref={register({required:true})} placeholder="Description"></textarea>
                                <label htmlFor="description">Description</label>
                            </div>                
        }
        


        {
            hasSortingNumber
                ?   <div className="form-field">
                        <input step="100" type="number" id="sortingNumber" className="form-control" name="sortingNumber" ref={register({required:true})} placeholder="SortingNumber" />
                        <label htmlFor="sortingNumber">Sorting number</label>
                    </div>
                :   ""
        }

        {
            selectInputs()
        }

        <div className="d-flex justify-content-between mt-4">

            <button className="btn btn-success" type="submit">Save</button>
            <button className="btn btn-dark" type="button" onClick={() => {cleanHook()}}>Close</button>
            {
                item === "new"
                    ?   ""
                    :   <div>
                            <button className="btn btn-danger mr-3" type="button" onClick={() => {handleDelete(item)}}>Remove</button>
                        </div>
            }
        </div>
      </form>
    );
}