import React from "react";

import ThemeListItem from "./ThemeListItem";

export default function ThemeList({quadrants, currentLevel, quadrantRefs, criterionRefs, themeRefs, definitions, setCurrentLevelState, setExecuteCriterionScrollState}) {
    const ListOfThemes = () => {
        if (quadrants == null) {
            return false;
        } else {

            return quadrants.map((quadrant, quadrantKey) => {
                var themesWithCriteria = [];

                quadrant.themes.forEach(theme => {
                    let filteredCriteria = theme.criteria.filter(criterion => criterion.level._id === currentLevel._id);

                    if (filteredCriteria.length > 0) {
                        themesWithCriteria.push(theme);
                    }
                });

                return themesWithCriteria.map((theme, key) => {
                    if (key === 0) {
                        return (
                            <div ref={themeRefs[theme._id]} key={key}>
                                <ThemeListItem thisRef={quadrantRefs[quadrant._id]} theme={theme} currentLevel={currentLevel} quadrantColorCode={quadrant.colorCode} definitions={definitions} setCurrentLevelState={setCurrentLevelState} criterionRefs={criterionRefs} setExecuteCriterionScrollState={setExecuteCriterionScrollState} />
                            </div>
                        )
                    }
                    return (
                        <div ref={themeRefs[theme._id]} key={key}>
                            <ThemeListItem thisRef={false} theme={theme} currentLevel={currentLevel} quadrantColorCode={quadrant.colorCode} definitions={definitions} setCurrentLevelState={setCurrentLevelState} criterionRefs={criterionRefs} setExecuteCriterionScrollState={setExecuteCriterionScrollState} />
                        </div>
                    )
                });
            });
        }
    };
    
    return (
        <ListOfThemes/>
    )
};