import Home from "../pages/CMS/home.js"
import ThemesList from "../pages/CMS/themes-list.js"
import CriteriaList from "../pages/CMS/criteria-list.js"
import QuadrantsList from "../pages/CMS/quadrants-list.js"
import LevelsList from "../pages/CMS/levels-list.js"
import DefinitionsList from "../pages/CMS/definitions-list.js"
import TemplatesList from "../pages/CMS/templates-list.js"
import WebtextsList from "../pages/CMS/webtexts-list.js"
import QuestionsList from "../pages/CMS/questions-list.js"

const CMSRoutes = [
    {
        path: "home",
        name: "Home",
        component: Home,
        hasProps: false
    },
    {
        path: "themes",
        name: "Themes",
        component: ThemesList,
        hasProps: true,
        propsTitle: "themes"
    },
    {
        path: "criteria",
        name: "Criteria",
        component: CriteriaList,
        hasProps: true,
        propsTitle: "criteria"
    },
    {
        path: "quadrants",
        name: "Quadrants",
        component: QuadrantsList,
        hasProps: true,
        propsTitle: "quadrants"
    },
    {
        path: "levels",
        name: "Levels",
        component: LevelsList,
        hasProps: true,
        propsTitle: "levels"
    },
    {
        path: "definitions",
        name: "Definitions",
        component: DefinitionsList,
        hasProps: true,
        propsTitle: "definitions"
    },
    {
        path: "templates",
        name: "Templates",
        component: TemplatesList,
        hasProps: true,
        propsTitle: "templates"
    },
    {
        path: "webtexts",
        name: "Webtexts",
        component: WebtextsList,
        hasProps: true,
        propsTitle: "webtexts"
    },
    {
        path: "questions",
        name: "Questions",
        component: QuestionsList,
        hasProps: true,
        propsTitle: "questions"
    }
];

export default CMSRoutes;