import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Editor } from "@tinymce/tinymce-react";
import "./Form.scss";
import dataHandler from "../../api/dataHandler";
import UploadService from "../../api/uploadService";

export default function SimpleFormWithRichText({item, collection, getAllData, setMessageState, setHasErrorState, setErrorMessageState, hasSortingNumber, setCurrentItemState, cleanHook}) {
    const [description, setDescriptionState] = useState(item.description);
    const uploadService = new UploadService();

    const { register, handleSubmit } = useForm({
        defaultValues: {
            title: item.title,
            description: item.description,
            sortingNumber: item.sortingNumber
        }
    });

    const onSubmit = async data => {
        setMessageState(false);
        setHasErrorState(false);
        setErrorMessageState(false);

        data.item__id = item._id;
        data.description = description;//Add tinymce value, tracked by handleEditorChange

        if (item === "new") {
            try {
                await dataHandler.post(`/${collection}/create`, data);
                setMessageState("Item successfully created.")
                getAllData();
                setCurrentItemState(null);
            } catch (err) {
                setErrorMessageState(err.message);
                setHasErrorState(true);
            }
        } else {
            if (hasSortingNumber) {
                data.sortingNumber = Number(data.sortingNumber);
                if (data.title === item.title && data.description === item.description && data.sortingNumber === item.sortingNumber) {
                    setErrorMessageState("Old and new values are the same.");
                    setHasErrorState(true);
                } else {
                    try {
                        await dataHandler.post(`/${collection}/update`, data);
                        setMessageState("Item successfully updated.")
                        getAllData();
                        setCurrentItemState(null);
                    } catch (err) {
                        setErrorMessageState(err.message);
                        setHasErrorState(true);
                    }
                }
            } else {
                if (data.title === item.title && data.description === item.description) {
                    setErrorMessageState("Old and new values are the same.");
                    setHasErrorState(true);
                } else {
                    try {
                        await dataHandler.post(`/${collection}/update`, data);
                        setMessageState("Item successfully updated.")
                        getAllData();
                        setCurrentItemState(null);
                    } catch (err) {
                        setErrorMessageState(err.message);
                        setHasErrorState(true);
                    }
                }
            }
        }        
    };

    const handleEditorChange = editorContent => {
        setDescriptionState(editorContent);
    };

    const filePickerCallback = (callback, value, meta) => {
        if (meta.filetype === 'image') {

            let randomNumber = Math.floor(Math.random() * Math.floor(9999999));//Add random number to form id to ensure multiple images can be uploaded in one edit

            var createdForm = document.createElement("FORM");
            createdForm.setAttribute("id", `myForm${randomNumber}`);
            createdForm.setAttribute("encType", "multipart/form-data");
            document.body.appendChild(createdForm);
            let createdInput = document.createElement("INPUT");
            createdInput.setAttribute("type", "file");
            createdInput.setAttribute("name", "image");
            createdInput.setAttribute("class", "d-none");
            document.getElementById(`myForm${randomNumber}`).appendChild(createdInput);

            createdInput.click();

            createdInput.onchange = async () => {
                let data = new FormData(createdForm);
                let file = createdInput.files[0];
                let uploadedImage = await uploadService.uploadImage(data);

                let tinymce = global.tinyMCE.activeEditor;

                tinymce.insertContent(`<img alt="${file.name}" src="${process.env.REACT_APP_API_BASE_URL}/${uploadedImage}" />`);
                tinymce.windowManager.close();
            };
        }
    };
     
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-field">
                <input id="title" className="form-control" name="title" ref={register} placeholder="Title" />
                <label htmlFor="title">Title</label>
            </div>
            <div className="form-field">
                <input id="location" className="form-control disabled" disabled placeholder="Location" defaultValue={item.location} />
                <label htmlFor="location">Location</label>
            </div>
            <div className="form-field">
                <Editor 
                    apiKey="cduiwmk6uesuolj14t5tm2yag3fr8csr336j75n7vb1x5uvs"
                    onEditorChange={handleEditorChange}
                    initialValue={item.description}
                    plugins="image imagetools link lists"
                    toolbar="undo redo | link | numlist bullist | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent"

                    init={{
                        file_browser_callback_types: 'image',
                        file_picker_callback: filePickerCallback,
                        height: "500px",
                        automatic_uploads: true
                    }}
                />
                <label className="mb-2 pl-0" htmlFor="tinymce">Description</label>
            </div>

            {
                hasSortingNumber
                    ?   <div className="form-field">
                            <input step="100" type="number" id="sortingNumber" className="form-control" name="sortingNumber" ref={register} placeholder="SortingNumber" />
                            <label htmlFor="sortingNumber">SortingNumber</label>
                        </div>
                    :   ""
            }

            <div className="d-flex justify-content-between">
                <button className="btn btn-success mt-2" type="submit">Save</button>
                <button className="btn btn-dark mt-2" type="button" onClick={() => {cleanHook()}}>Close</button>
            </div>
        </form>
    );
}