import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import "./QuadrantNavbar.scss";

export default function QuadrantNavBar({quadrants, currentQuadrant, setCurrentQuadrantState, setCurrentThemeState}) {

    const handleQuadrantClick = quadrant => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"//Animation doesn't work in safari
        });
        setCurrentQuadrantState(quadrant);
        setCurrentThemeState(quadrant.themes[0]);
    };

    const QuadrantLinks = () => {
        return quadrants.map((quadrant, key) => {
            return (
                <div className="quadrant-navbar-item d-flex flex-grow-1" key={key}>
                    <button
                        className={`btn d-flex align-items-center justify-content-center flex-grow-1 ${currentQuadrant && currentQuadrant._id === quadrant._id ? "active" : ""}`}
                        onClick={() => {handleQuadrantClick(quadrant)}}
                        style={{borderColor:quadrant.colorCode}}
                    >
                        {quadrant.title}
                    </button>
                </div>
            )
        });
    };

    return (
        <nav className="quadrant-navbar">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-between overflow-x-scroll">
                            <div className="navbar-item d-flex align-items-center mr-5">
                                <NavLink
                                    to="/"
                                    className="nav-link"
                                    activeClassName="d-none"
                                    end
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                    Tool homepage
                                </NavLink>
                            </div>
                            <QuadrantLinks></QuadrantLinks>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
};