import React from "react";
import Spinner from 'react-bootstrap/Spinner';
import "./Loader.scss";

export default function Loader() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12 d-flex justify-content-center align-items-center vh-100">
                    <Spinner animation="grow" className="spinner" role="status"/>                
                </div>
            </div>
        </div>
    );
}