import React, { useState, useEffect } from "react";

import Fade from 'react-reveal/Fade';
import BasicListItem from "../../components/CMS/BasicListItem";
import FormWithSelectAndUpload from "../../components/Form/FormWithSelectAndUpload";
import Alert from 'react-bootstrap/Alert';

export default function CriteriaList({criteria, levels, themes, templates, getAllData}) {
    const [currentCriterion, setCurrentCriterionState] = useState(null);
    const [currentLevel, setCurrentLevelState] = useState("all");
    const [currentTheme, setCurrentThemeState] = useState("all");
    const [filteredCriteria, setFilteredCriteriaState] = useState(null);
    const [message, setMessageState] = useState(false);
    const [hasError, setHasErrorState] = useState(false);
    const [errorMessage, setErrorMessageState] = useState(false);

    useEffect(() => {
        let criteriaArray = criteria;
        if (currentLevel !== "all") {
            criteriaArray = criteriaArray.filter(criterion => criterion.level._id === currentLevel);
        }
        if (currentTheme !== "all") {
            criteriaArray = criteriaArray.filter(criterion => criterion.theme._id === currentTheme)
        }
        setFilteredCriteriaState(criteriaArray);
    }, [criteria, currentLevel, currentTheme]);

    const cleanHook = () => {
        setCurrentCriterionState(null);
        setMessageState(false);
        setHasErrorState(false);
        setErrorMessageState(false);
    };

    useEffect(() => {
        if (currentCriterion !== null) {
            setMessageState(false);
            setHasErrorState(false);
            setErrorMessageState(false);
        }
    }, [currentCriterion]);

    const ListOfCriteria = () => {
        if (filteredCriteria == null) {
            return false;
        } else if (filteredCriteria.length === 0) {
            return (
                <Alert className="mt-3" variant="primary">No items yet.</Alert>
            );
        } else {
            return filteredCriteria.map((criterion, key) => {
                return (
                    <BasicListItem item={criterion} setCurrentItemState={setCurrentCriterionState} key={key} />
                )
            });
        }
    };

    // const handleDelete = async criterion => {
    //     try {
    //         await dataHandler.post("/criteria/delete", {item__id: currentCriterion._id});
    //         setMessageState("Item successfully deleted.");
    //         getAllData();
    //         setCurrentCriterionState(null);
    //     } catch (err) {
    //         setErrorMessageState(err.message);
    //         setHasErrorState(true);
    //     }
    // };

    const filterInputHandler = async e => {
        e.preventDefault();
        let currentFilter = e.currentTarget;

        if (currentFilter.name === "levelSelect") {
            setCurrentLevelState(currentFilter.value);
        } else setCurrentThemeState(currentFilter.value);
    };
    
    if (currentCriterion === null) {
        return (
            <>
                <Fade>
                    <div className="row d-flex align-items-center">
                        <div className="col">
                            <h1>Criteria</h1>
                        </div>

                        <div className="col-12 col-lg-5">
                            <div className="d-flex my-lg-0 my-2">
                                {
                                    levels.length > 0
                                        ?   <select defaultValue={currentLevel} onInput={(e) => filterInputHandler(e)} className="form-control" id="theme" name={"levelSelect"}>
                                                <option value="all">All</option>
                                                {
                                                    levels.map((option, key) => {
                                                        return <option value={option._id} key={key}>{option.title}</option>
                                                    })
                                                }
                                            </select>
                                        :   ""
                                }
                                {
                                    themes.length > 0
                                        ?   <select defaultValue={currentTheme} onInput={(e) => filterInputHandler(e)} className="form-control ml-2" id="theme" name={"themeSelect"}>
                                                <option value="all">All</option>
                                                {
                                                    themes.map((option, key) => {
                                                        return <option value={option._id} key={key}>{option.title}</option>
                                                    })
                                                }
                                            </select>
                                        :   ""
                                }
                            </div>
                        </div>

                        {
                            message 
                                ? <Alert className="mb-0" variant="success" dismissible onClose={() => setMessageState(false)}>{message}</Alert> 
                                : ""
                        }

                        {
                            hasError 
                                ? <Alert className="mb-0" variant="danger" dismissible onClose={() => setHasErrorState(false)}>{errorMessage}</Alert> 
                                : ""
                        }

                        <div className="col d-flex justify-content-start justify-content-lg-end">
                            <button className="btn btn-primary" onClick={() => {setCurrentCriterionState("new")}}>Add new</button>
                        </div>
                    </div>
                </Fade>
                {ListOfCriteria ? <ListOfCriteria/> : ''}
            </>
        )
    } else {
        return (
            <div className="card mb-3">
                <div className="card-body">
                    <FormWithSelectAndUpload
                        item={currentCriterion} 
                        collection="criteria" 
                        getAllData={getAllData} 
                        setMessageState={setMessageState}
                        setHasErrorState={setHasErrorState}
                        setErrorMessageState={setErrorMessageState}
                        setCurrentCriterionState={setCurrentCriterionState}
                        hasSortingNumber={false}
                        selectArray={[{name: "Level", options: levels}, {name: "Theme", options: themes}]}
                        criteriaCheckBox={{name: "Criteria", options: criteria}}
                        templatesCheckBox={{name: "Templates", options: templates}}
                        criteria={criteria}
                        cleanHook={cleanHook}
                    />
                    <div className="d-flex justify-content-between">
                        {
                            message 
                                ? <Alert className="mt-3" variant="success" dismissible onClose={() => setMessageState(false)}>{message}</Alert> 
                                : ""
                        }

                        {
                            hasError 
                                ? <Alert className="mt-3" variant="danger" dismissible onClose={() => setHasErrorState(false)}>{errorMessage}</Alert> 
                                : ""
                        }                        
                    </div>
                </div>
            </div>
        )
    }
};