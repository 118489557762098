import React from "react";

import LevelListItem from "./LevelListItem";

export default function LevelList({levels, currentQuadrant, currentTheme, definitions, setCurrentThemeState, setCurrentQuadrantState, setErrorMessageState, criterionRefs, levelRefs, setExecuteCriterionScrollState}) {

    const ListOfLevels = () => {
        if (levels == null) {
            return false;
        } else {

            return levels.map((level, levelKey) => {
                return (
                    <div ref={levelRefs[level._id]} key={levelKey}>
                        <LevelListItem
                            // thisRef={levelRefs[level._id]}
                            level={level}
                            currentTheme={currentTheme}
                            quadrantColorCode={currentQuadrant.colorCode}
                            definitions={definitions}
                            key={levelKey} 
                            setCurrentThemeState={setCurrentThemeState}
                            setCurrentQuadrantState={setCurrentQuadrantState}
                            setErrorMessageState={setErrorMessageState}
                            criterionRefs={criterionRefs}
                            setExecuteCriterionScrollState={setExecuteCriterionScrollState}
                        />
                    </div>
                )                    
            });
        }
    };
    
    return (
        <ListOfLevels/>
    )
};