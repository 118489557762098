import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Fade from 'react-reveal/Fade';

import QuadrantNavBar from "../../components/Frontend/ThemeView/QuadrantNavbar.js";
import ThemeNavBar from "../../components/Frontend/ThemeView/ThemeNavbar.js";
import LevelList from "../../components/Frontend/ThemeView/LevelList.js";
import Alert from 'react-bootstrap/Alert';

export default function Theme({levels, themes, quadrants, definitions}) {
    const [currentQuadrant, setCurrentQuadrantState] = useState(quadrants[0]);
    const [currentTheme, setCurrentThemeState] = useState(quadrants[0].themes[0]);
    const [errorMessage, setErrorMessageState] = useState(false);
    const [executeCriterionScroll, setExecuteCriterionScrollState] = useState({execute: false, criterion: null});
    const [executeLevelScroll, setExecuteLevelScrollState] = useState({execute: false, level: null});
    const [criterionRefsArray, setCriterionRefsArrayState] = useState(false);
    const [levelRefsArray, setLevelRefsArrayState] = useState(false);

    const location = useLocation();

    useEffect(() => {//Create levelrefs needed for scrolling to elements
        const createLevelRefs = () => {
            let arrayOfLevelIds = [];

            levels.forEach(level => {
                arrayOfLevelIds.push(level._id);
            });
    
            let levelRefs = arrayOfLevelIds.reduce((acc, value) => {
                acc[value] = React.createRef();
                return acc;
            }, {});
            
            return levelRefs;
        };
    
        let array = createLevelRefs();
        setLevelRefsArrayState(array);
    }, [levels]);

    useEffect(() => {
        const scrollToLevel = level => {
            let el = levelRefsArray[level._id].current;
            let top = el.getBoundingClientRect().top + window.scrollY - 160;//Align clicked level to top

            window.scrollTo({
                top: top,
                behavior: "smooth"//Animation doesn't work in safari
            });

            setExecuteLevelScrollState({execute: false, level: null});
        };

        if (executeLevelScroll.execute === true) {
            scrollToLevel(executeLevelScroll.level);
        }
    }, [executeLevelScroll, levelRefsArray]);

    useEffect(() => {//Select quadrant and theme when switching from level view to theme view     
        if (location.state !== null) {
            setCurrentThemeState(location.state.theme);
            setCurrentQuadrantState(location.state.quadrant);
            setExecuteLevelScrollState({execute: true, level: location.state.level});
        }
    }, [location]);

    useEffect(() => {//Create criterionrefs needed for scrolling to elements
        const createCriterionRefs = () => {
            let arrayOfCriterionIds = [];

            quadrants.forEach(quadrant => {
                quadrant.themes.forEach(theme => {
                    theme.criteria.forEach(criterion => {
                        arrayOfCriterionIds.push(criterion._id);
                    });
                });
            });
    
            let criterionRefs = arrayOfCriterionIds.reduce((acc, value) => {
                acc[value] = React.createRef();
                return acc;
            }, {});
            
            return criterionRefs;
        };
    
        let array = createCriterionRefs();
        setCriterionRefsArrayState(array);
    }, [quadrants]);

    useEffect(() => {
        const scrollToCriterion = criterion => {
            let el = criterionRefsArray[criterion].current;
            let top = el.getBoundingClientRect().top + window.scrollY - 160;//Align clicked criterion to top

            window.scrollTo({
                top: top,
                behavior: "smooth"//Animation doesn't work in safari
            });

            setExecuteCriterionScrollState({execute: false, criterion: null});
        };

        if (executeCriterionScroll.execute === true) {
            scrollToCriterion(executeCriterionScroll.criterion);
        }
    }, [executeCriterionScroll, criterionRefsArray]);

    return (
        <div className="theme-view">

            <QuadrantNavBar 
                quadrants={quadrants}
                currentQuadrant={currentQuadrant}
                setCurrentQuadrantState={setCurrentQuadrantState}
                setCurrentThemeState={setCurrentThemeState}
            />

            <ThemeNavBar 
                currentQuadrant={currentQuadrant}
                currentTheme={currentTheme}
                setCurrentThemeState={setCurrentThemeState}
            />

            <div className="spacer -xl"></div>

            <Fade>
                <div>
                    <div className="d-flex justify-content-between">
                        <span className="h2 mb-3">{currentTheme.title}</span>
                        {errorMessage ? <Alert className="m-0" variant="danger" dismissible onClose={() => {setErrorMessageState(false)}}>Could not fetch theme and quadrant: {errorMessage}</Alert> : ""}
                    </div>
                </div>
            </Fade>

            <LevelList
                levels={levels}
                currentQuadrant={currentQuadrant}
                currentTheme={currentTheme}
                definitions={definitions}
                setCurrentThemeState={setCurrentThemeState}
                setCurrentQuadrantState={setCurrentQuadrantState}
                setErrorMessageState={setErrorMessageState}
                criterionRefs={criterionRefsArray}
                levelRefs={levelRefsArray}
                setExecuteCriterionScrollState={setExecuteCriterionScrollState}
            />

        </div>
    )
};

