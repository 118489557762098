import React, { useState, useEffect } from "react";
import dataHandler from "../../api/dataHandler";

import QuestionListItem from "../../components/CMS/QuestionListItem";
import CategoryForm from "../../components/Form/CategoryForm";
import Alert from 'react-bootstrap/Alert';
import FormWithSelect from "../../components/Form/FormWithSelect";
import Fade from 'react-reveal/Fade';

export default function QuestionsList({questions, categories, getAllData}) {
    const [currentQuestion, setCurrentQuestionState] = useState(null);
    const [categoryMessage, setCategoryMessageState] = useState(false);
    const [questionMessage, setQuestionMessageState] = useState(false);
    const [hasError, setHasErrorState] = useState(false);
    const [errorMessage, setErrorMessageState] = useState(false);

    const cleanHook = () => {
        setCurrentQuestionState(null);
        setQuestionMessageState(false);
        setCategoryMessageState(false);
        setHasErrorState(false);
        setErrorMessageState(false);
    };

    useEffect(() => {
        if (currentQuestion !== null) {
            setQuestionMessageState(false);
            setHasErrorState(false);
            setErrorMessageState(false);
        }
    }, [currentQuestion]);

    const ListOfQuestions = () => {
        if (questions === null) {
            return false;
        } else if (questions.length === 0) {
            return (
                <Alert className="mt-3" variant="primary">No items yet.</Alert>
            );
        } else {
            return questions.map((question, key) => {
                return (
                    <QuestionListItem question={question} setCurrentQuestionState={setCurrentQuestionState} key={key} />
                )
            });
        }
    };

    const ListOfCategories = () => {
        if (categories === null) {
            return false;
        } else if (categories.length === 0) {
            return (
                <Alert className="mt-3" variant="primary">No items yet.</Alert>
            );
        } else {
            return categories.map((category, key) => {
                return (
                    <div className="mt-1" key={key}>
                        <div className="d-flex justify-content-between">
                            <span><strong>{category.title}</strong></span>
                            <button type="button" onClick={(e) => {handleDelete(e)}} name="categories" value={category._id} className="btn btn-sm btn-outline-danger ml-3">Delete</button>
                        </div>
                    </div>
                );
            });
        }
    };

    const handleDelete = async e => {
        e.preventDefault();
        let collection = e.currentTarget.name;
        let id = e.currentTarget.value;
        let questionId = currentQuestion._id;
        
        try {
            let response = await dataHandler.post(`/${collection}/delete`, { item__id: id, questionId: questionId });
            currentQuestion === "new" ? setCurrentQuestionState("new") : setCurrentQuestionState(response.data.question);
            setCategoryMessageState(response.data.message);
            getAllData();
        } catch (err) {
            err.response ? setErrorMessageState(err.response.data.message) : setErrorMessageState(err.message);
            setHasErrorState(true);
        }
    };

    const handleQuestionDelete = async question => {
        try {
            await dataHandler.post("/questions/delete", { item__id: currentQuestion._id });
            setQuestionMessageState("Item successfully deleted.")
            getAllData();
            setCurrentQuestionState(null);
        } catch (err) {
            setErrorMessageState(err.message);
            setHasErrorState(true);
        }
    };
    
    if (currentQuestion == null) {
        return (
            <>
                <Fade>
                    <div className="d-flex justify-content-between">
                        <h1>Questions</h1>

                        {
                            questionMessage 
                                ? <Alert className="mb-0" variant="success" dismissible onClose={() => setQuestionMessageState(false)}>{questionMessage}</Alert> 
                                : ""
                        }

                        {
                            hasError 
                                ? <Alert className="mb-0" variant="danger" dismissible onClose={() => setHasErrorState(false)}>{errorMessage}</Alert> 
                                : ""
                        }

                        <button className="btn btn-primary" onClick={() => {setCurrentQuestionState("new")}}>Add new</button>
                    </div>
                </Fade>
                {ListOfQuestions ? <ListOfQuestions/> : ''}
            </>
        )
    } else {
        return (
            <div className="row">
                <div className="col-8">
                    <div className="card mb-3">
                        <div className="card-body">
                            <FormWithSelect 
                                item={currentQuestion}
                                collection="questions"
                                getAllData={getAllData}
                                setMessageState={setQuestionMessageState}
                                setHasErrorState={setHasErrorState}
                                setErrorMessageState={setErrorMessageState}
                                hasSortingNumber={false}
                                selectArray={[{name: 'Category', options: categories}]}
                                setCurrentItemState={setCurrentQuestionState}
                                handleDelete={handleQuestionDelete}
                                cleanHook={cleanHook}
                            />
                            <div className="d-flex justify-content-between">
                                {
                                    questionMessage 
                                        ? <Alert className="mt-3" variant="success" dismissible onClose={() => setQuestionMessageState(false)}>{questionMessage}</Alert> 
                                        : ""
                                }

                                {
                                    hasError 
                                        ? <Alert className="mt-3" variant="danger" dismissible onClose={() => setHasErrorState(false)}>{errorMessage}</Alert> 
                                        : ""
                                }                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="my-3 col-12">
                                    {
                                        categoryMessage 
                                            ? <Alert variant="success" dismissible onClose={() => setCategoryMessageState(false)}>{categoryMessage}</Alert> 
                                            : ""
                                    }

                                    <p className="m-0 form-title">Categories</p>
                                    {ListOfCategories ? <ListOfCategories /> : ""}

                                </div>
                            </div>
                                <CategoryForm 
                                    getAllData={getAllData}
                                    setMessageState={setCategoryMessageState}
                                    setHasErrorState={setHasErrorState}
                                    setErrorMessageState={setErrorMessageState}
                                />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};