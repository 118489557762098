import React from "react";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import dataProvider from "../../../api/dataProvider";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import "../FrontendListItem.scss";
import CriterionListItem from "../CriterionListItem";

export default function LevelListItem({level, currentTheme, quadrantColorCode, definitions, setCurrentThemeState, setCurrentQuadrantState, setErrorMessageState, criterionRefs, setExecuteCriterionScrollState}) {
    const navigate = useNavigate();

    const arrowClickHandler = async criterion => {
        try {
            await Promise.all([
                dataProvider.get(`/theme?_id=${criterion.theme._id}`),
                dataProvider.get(`/quadrant?_id=${criterion.theme.quadrant}`)
            ]).then(([theme, quadrant]) => {
                setCurrentThemeState(theme.data);
                setCurrentQuadrantState(quadrant.data);
                setExecuteCriterionScrollState({execute: true, criterion: criterion._id});
            });
        } catch (err) {
            setErrorMessageState(err.message);
            window.scrollTo(0,0);
        }
    };

    const viewClickHandler = level => {
        navigate('/level', {state: {level: level, theme: currentTheme}});
    };
    
    const ListOfCriteria = () => {
        let filteredCriteria = level.criteria.filter(criterion => criterion.theme === currentTheme._id);

        return filteredCriteria.map((criterion, key) => {
            return ( 
                <CriterionListItem key={key} thisRef={criterionRefs[criterion._id]} criterion={criterion} definitions={definitions} setCurrentSelectionState={arrowClickHandler} />
            );
        });
    };

    return (
        <div className="row">
            <div className="col">
                <div className="card frontend-list-item mt-3" style={{borderLeftColor:quadrantColorCode}}>
                    <div className="card-body -no-padding">
                        <div className="row">
                            <div className="col-12 col-lg-3">
                                <div className="p-4">
                                    <p className="-tg-small-title">{level.title}</p>
                                    <p className="-tg-small-title -bold ww-break">{level.description}</p>
                                    <span
                                        onClick={() => {viewClickHandler(level)}}
                                        className="-tg-link -turq"
                                    >Level view
                                        <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 col-lg-9">

                                <ListOfCriteria></ListOfCriteria>

                            </div>
                        </div>                
                    </div>
                </div>
            </div>
        </div>
    )
};