import React from "react";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import "./Highlighter.scss";

export default function Highlighter({className, text, definitionsArray}) {
    const reactStringReplace = require('react-string-replace')
    if (definitionsArray === undefined || definitionsArray.length === 0) {
        return <span className={className}>{text}</span>;
    }

    const orderDefinitionsByWordCount = (a, b) => {
        if (a.split(' ').length < b.split(' ').length) {
            return 1;
        }
        if (a.split(' ').length > b.split(' ').length) {
            return -1;
        }
        return 0;
    }

    let definitions = [];

    definitionsArray.map((definition, key) => {
        return definitions.push(definition.title.toLowerCase().trim());
    });

    definitions = definitions.sort(orderDefinitionsByWordCount); //Sort definitions by word length so longest possible match hits first

    const popover = match => {
        let definition = definitionsArray.find(definition => definition.title.toLowerCase() === match.toLowerCase());
        if (definition === undefined) return false;
        return (
            <Popover id="popover-basic">
                <Popover.Title as="h3">{definition.title}</Popover.Title>
                <Popover.Content>
                    {definition.description}
                </Popover.Content>
            </Popover>
      );
    };

    const createPattern = definition => {
        //Escape special characters first (if applicable)
        let definitionWithEscapedSpecChar = definition.replace(/[!@#$%^&*()+=\-[\]\\';,./{}|":<>?~_]/g, "\\$&");
        let pattern = new RegExp(`\\b(${definitionWithEscapedSpecChar})\\b`, "ig");
        return pattern;
    };

    var textWithHighlights = text;

    definitions.forEach(definition => {
        let pattern = createPattern(definition);
        textWithHighlights = reactStringReplace(textWithHighlights, pattern, (match, i, o) => {
            return (<OverlayTrigger key={match+i+o} trigger={["hover", "focus"]} placement="bottom" overlay={popover(match)}><span className="highlight">{match}</span></OverlayTrigger>)
        })
    });

    return <span className={className}>{textWithHighlights}</span>;
};