import React from "react";
import SimpleNavBar from "../../components/Frontend/SimpleNavbar";
import Fade from 'react-reveal/Fade';

export default function WebtextPage({webtext}) {

    const createMarkupBody = () => {
        return {__html: webtext.description}
    };

    return (
        <>
            <SimpleNavBar />

            <div className="spacer -sm"></div>

            <Fade>
                <span className="-tg-title">{webtext.title}</span>
                <div dangerouslySetInnerHTML={createMarkupBody()}></div>
            </Fade>
        </>
    )
};