import React, { useState, useEffect } from "react";
import dataHandler from "../../api/dataHandler";

import BasicListItem from "../../components/CMS/BasicListItem";
import SimpleFormWithUpload from "../../components/Form/SimpleFormWithUpload";
import Alert from 'react-bootstrap/Alert';
import Fade from 'react-reveal/Fade';

export default function TemplatesList({templates, getAllData}) {
    const [currentTemplate, setCurrentTemplateState] = useState(null);
    const [message, setMessageState] = useState(false);
    const [hasError, setHasErrorState] = useState(false);
    const [errorMessage, setErrorMessageState] = useState(false);

    const cleanHook = () => {
        setCurrentTemplateState(null);
        setMessageState(false);
        setHasErrorState(false);
        setErrorMessageState(false);
    };

    useEffect(() => {
        if (currentTemplate !== null) {
            setMessageState(false);
            setHasErrorState(false);
            setErrorMessageState(false);
        }
    }, [currentTemplate]);

    const ListOfTemplates = () => {
        if (templates == null) {
            return false;
        } else if (templates.length === 0) {
            return (
                <Alert className="mt-3" variant="primary">No items yet.</Alert>
            );
        } else {
            return templates.map((template, key) => {
                return (
                    <BasicListItem item={template} setCurrentItemState={setCurrentTemplateState} key={key} />
                )
            });
        }
    };

    const handleDelete = async template => {
        try {
            await dataHandler.post("/templates/delete", { item__id: currentTemplate._id });
            setMessageState("Item successfully deleted.")
            getAllData();
            setCurrentTemplateState(null);
        } catch (err) {
            setErrorMessageState(err.message);
            setHasErrorState(true);
        }
    };
    
    if (currentTemplate == null) {
        return (
            <>
                <Fade>
                    <div className="d-flex justify-content-between">
                        <h1>Templates</h1>

                        {
                            message 
                                ? <Alert className="mb-0" variant="success" dismissible onClose={() => setMessageState(false)}>{message}</Alert> 
                                : ""
                        }

                        {
                            hasError 
                                ? <Alert className="mb-0" variant="danger" dismissible onClose={() => setHasErrorState(false)}>{errorMessage}</Alert> 
                                : ""
                        }

                        <button className="btn btn-primary" onClick={() => {setCurrentTemplateState("new")}}>Add new</button>
                    </div>
                </Fade>
                {ListOfTemplates ? <ListOfTemplates/> : ''}
            </>
        )
    } else {
        return (
            <div className="card mb-3">
                <div className="card-body">
                    <SimpleFormWithUpload 
                        item={currentTemplate}
                        getAllData={getAllData}
                        setMessageState={setMessageState}
                        setHasErrorState={setHasErrorState}
                        setErrorMessageState={setErrorMessageState}
                        setCurrentTemplateState={setCurrentTemplateState}
                        hasSortingNumber={false}
                        handleDelete={handleDelete}
                        cleanHook={cleanHook}
                    />
                    <div className="d-flex justify-content-between">
                        {
                            message 
                                ? <Alert className="mt-3" variant="success" dismissible onClose={() => setMessageState(false)}>{message}</Alert> 
                                : ""
                        }

                        {
                            hasError 
                                ? <Alert className="mt-3" variant="danger" dismissible onClose={() => setHasErrorState(false)}>{errorMessage}</Alert> 
                                : ""
                        }                        
                    </div>
                </div>
            </div>
        )
    }
};