import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import "../Navbar.scss";

export default function SimpleNavBar() {

    return (
        <nav className="navbar">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-between">
                            <div className="navbar-item ml-5">
                                <NavLink
                                    to="/"
                                    className="nav-link px-0"
                                    end
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                    Tool homepage
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
};