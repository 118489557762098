import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import "./LevelNavbar.scss";

export default function LevelNavBar({levels, currentLevel, setCurrentLevelState}) {

    const handleLevelClick = level => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"//Animation doesn't work in safari
        });
        setCurrentLevelState(level)
    };

    const LevelLinks = () => {
        return levels.map((level, key) => {
            return (
                <div className="level-navbar-item d-flex flex-grow-1" key={key}>
                    <button
                        className={`btn d-flex align-items-center justify-content-center flex-grow-1 ${currentLevel && currentLevel._id === level._id ? "active" : ""}`}
                        onClick={() => {handleLevelClick(level)}}
                    >
                        {level.title}
                    </button>
                </div>
            )
        });
    };

    return (
        <nav className="level-navbar">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-between overflow-x-scroll">
                            <div className="navbar-item d-flex align-items-center mr-5">
                                <NavLink
                                    to="/"
                                    className="nav-link home-link"
                                    activeClassName="d-none"
                                    end
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                    Tool homepage
                                </NavLink>
                            </div>
                            <LevelLinks></LevelLinks>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
};