import React from "react";
import { useForm } from "react-hook-form";
import "./Form.scss";
import dataHandler from "../../api/dataHandler";

export default function SimpleForm({item, collection, getAllData, setMessageState, setHasErrorState, setErrorMessageState, hasSortingNumber, hasDescription, setCurrentItemState, handleDelete, cleanHook}) {

    const { register, handleSubmit } = useForm({
        defaultValues: {
            title: item.title,
            description: item.description,
            sortingNumber: item.sortingNumber
        }
    });

    const onSubmit = async data => {
        setMessageState(false);
        setHasErrorState(false);
        setErrorMessageState(false);

        data.item__id = item._id;

        if (item === "new") {
            try {
                await dataHandler.post(`/${collection}/create`, data);
                setMessageState("Item successfully created.")
                getAllData();
                setCurrentItemState(null);
            } catch (err) {
                setErrorMessageState(err.message);
                setHasErrorState(true);
            }
        } else {
            if (hasSortingNumber) {
                data.sortingNumber = Number(data.sortingNumber);
                if (data.title === item.title && data.description === item.description && data.sortingNumber === item.sortingNumber) {
                    setErrorMessageState("Old and new values are the same.");
                    setHasErrorState(true);
                } else {
                    try {
                        await dataHandler.post(`/${collection}/update`, data);
                        setMessageState("Item successfully updated.")
                        getAllData();
                        setCurrentItemState(null);
                    } catch (err) {
                        setErrorMessageState(err.message);
                        setHasErrorState(true);
                    }
                }
            } else {
                if (data.title === item.title && data.description === item.description) {
                    setErrorMessageState("Old and new values are the same.");
                    setHasErrorState(true);
                } else {
                    try {
                        await dataHandler.post(`/${collection}/update`, data);
                        setMessageState("Item successfully updated.")
                        getAllData();
                        setCurrentItemState(null);
                    } catch (err) {
                        setErrorMessageState(err.message);
                        setHasErrorState(true);
                    }
                }
            }
        }        
    };
     
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-field">
            <input id="title" className="form-control" name="title" ref={register} placeholder="Title" />
            <label htmlFor="title">Title</label>
        </div>

        {
            hasDescription === false
                ?   ""
                :   <div className="form-field">
                        <textarea id="description" className="form-control" name="description" ref={register({required:true})} placeholder="Description"></textarea>            
                        <label htmlFor="description">Description</label>
                    </div>
        }

        {
            hasSortingNumber
                ?   <div className="form-field">
                        <input step="100" type="number" id="sortingNumber" className="form-control" name="sortingNumber" ref={register} placeholder="SortingNumber" />
                        <label htmlFor="sortingNumber">SortingNumber</label>
                    </div>
                :   ""
        }

        <div className="d-flex justify-content-between">
            <button className="btn btn-success" type="submit">Save</button>
            <button className="btn btn-dark" type="button" onClick={() => {cleanHook()}}>Close</button>
            {
                handleDelete === false || item === "new"
                    ?   ""
                    :   <div>
                            <button className="btn btn-danger mr-3" type="button" onClick={() => {handleDelete(item)}}>Remove</button>
                        </div>
            }
        </div>
    </form>
    );
}