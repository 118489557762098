import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import routes from "./routes/CMSRoutes";

import Alert from 'react-bootstrap/Alert';

import Loader from "./components/Loader.js"
import NavBar from "./components/CMS/Navbar.js"
import Home from "./pages/CMS/home.js"
import LevelsList from "./pages/CMS/levels-list";
import ThemesList from "./pages/CMS/themes-list";
import QuadrantsList from "./pages/CMS/quadrants-list.js"
import CriteriaList from "./pages/CMS/criteria-list.js"
import DefinitionsList from "./pages/CMS/definitions-list.js"
import TemplatesList from "./pages/CMS/templates-list.js"
import WebtextsList from "./pages/CMS/webtexts-list.js"
import QuestionsList from "./pages/CMS/questions-list.js"

import dataProvider from "./api/dataProvider";

export default function CMS({user}) {
    const [themes, setThemesState] = useState(null);
    const [levels, setLevelsState] = useState(null);
    const [quadrants, setQuadrantsState] = useState(null);
    const [criteria, setCriteriaState] = useState(null);
    const [definitions, setDefinitionsState] = useState(null);
    const [templates, setTemplatesState] = useState(null);
    const [webtexts, setWebtextsState] = useState(null);
    const [questions, setQuestionsState] = useState(null);
    const [categories, setCategoriesState] = useState(null);

    const [error, setErrorState] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    
    const getAllData = async () => {//TODOIMMENSE rewrite to Promise.allSettled with correct error handling?
        await Promise.all([
            dataProvider.get("/themes"),
            dataProvider.get("/levels"),
            dataProvider.get("/quadrants"),
            dataProvider.get("/criteria"),
            dataProvider.get("/definitions"),
            dataProvider.get("/templates"),
            dataProvider.get("/webtexts"),
            dataProvider.get("/questions"),
            dataProvider.get("/categories")
        ]).then(([themes, levels, quadrants, criteria, definitions, templates, webtexts, questions, categories]) => {
            setThemesState(themes.data);
            setLevelsState(levels.data);
            setQuadrantsState(quadrants.data);
            setCriteriaState(criteria.data);
            setDefinitionsState(definitions.data);
            setTemplatesState(templates.data);
            setWebtextsState(webtexts.data);
            setQuestionsState(questions.data);
            setCategoriesState(categories.data);

            setErrorState(false);
            setIsLoading(false);
        }).catch(err => {
            setErrorState(err.message);
            setIsLoading(false);
            // themes ? setThemesState(themes.data) : setThemesState(null);
            // levels ? setLevelsState(levels.data) : setLevelsState(null);
            // quadrants ? setQuadrantsState(quadrants.data) : setQuadrantsState(null);
            // criteria ? setCriteriaState(criteria.data) : setCriteriaState(null);
            // definitions ? setDefinitionsState(definitions.data) : setDefinitionsState(null);
            // templates ? setTemplatesState(templates.data) : setTemplatesState(null);
            // webtexts ? setWebtextsState(webtexts.data) : setWebtextsState(null);
            // questions ? setQuestionsState(questions.data) : setQuestionsState(null);
            // categories ? setCategoriesState(categories.data) : setCategoriesState(null);
        });
    }

    useEffect(() => {
        getAllData();
    }, []);

    if (isLoading) return <Loader className="full-screen-loader" />;
    if (error) return (
        <>
            <div className="spacer -sm"></div>
            <Alert variant="danger" dismissible onClose={() => setErrorState(false)}>{error}</Alert>
        </>
    );
    return (
        <>
            <div className="spacer -lg"></div>

            <NavBar routes={routes}></NavBar>

            <Routes>
                <Route
                    path="/home"
                    element={
                        <Home
                            user={user}
                        />
                    }
                />
                <Route
                    path="/levels"
                    element={
                        <LevelsList
                            levels={levels}
                            getAllData={getAllData}
                        />
                    }
                />
                <Route
                    path="/themes"
                    element={
                        <ThemesList
                            themes={themes}
                            quadrants={quadrants}
                            getAllData={getAllData}
                        />
                    }
                />
                <Route
                    path="/quadrants"
                    element={
                        <QuadrantsList
                            quadrants={quadrants}
                            getAllData={getAllData}
                        />
                    }
                />
                <Route
                    path="/criteria"
                    element={
                        <CriteriaList
                            criteria={criteria}
                            levels={levels}
                            themes={themes}
                            templates={templates}
                            getAllData={getAllData}
                        />
                    }
                />
                <Route
                    path="/definitions"
                    element={
                        <DefinitionsList
                            definitions={definitions}
                            getAllData={getAllData}
                        />
                    }
                />
                <Route
                    path="/templates"
                    element={
                        <TemplatesList
                            templates={templates}
                            getAllData={getAllData}
                        />
                    }
                />
                <Route
                    path="/webtexts"
                    element={
                        <WebtextsList
                            webtexts={webtexts}
                            getAllData={getAllData}
                        />
                    }
                />
                <Route
                    path="/questions"
                    element={
                        <QuestionsList
                            questions={questions}
                            categories={categories}
                            getAllData={getAllData}
                        />
                    }
                />
                <Route
                    path="/*"
                    element={<Navigate to="/admin/home" />}
                />                
            </Routes>
        </>
    )
}
