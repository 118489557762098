import React from "react";
import Fade from 'react-reveal/Fade';

export default function QuestionListItem({question, setCurrentQuestionState}) {
    return (
        <Fade bottom>
            <div className="card mt-3">
                <div className="card-body">
                    <div className="d-flex justify-content-between">
                        <p>{question.question}</p>
                        <div>
                            <button className="btn btn-primary" onClick={() => {setCurrentQuestionState(question)}}>Edit</button>
                        </div>
                    </div>                
                </div>
            </div>
        </Fade>
    )
};