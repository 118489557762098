import React from "react";

import "./QuadrantNavbar.scss";
 
export default function QuadrantNavBar({quadrants, scrollToQuadrant}) {

    const QuadrantItems = () => {
        return quadrants.map((quadrant, key) => {
            return (
                // <div className="quadrant-navbar-item d-flex flex-grow-1" key={key}>
                <div className="quadrant-navbar-item col-6 col-lg-3" key={key}>
                    <button
                        className={"btn d-flex align-items-center justify-content-center flex-grow-1 my-1"}
                        onClick={() => {scrollToQuadrant(quadrant._id)}}
                        style={{borderTopColor:quadrant.colorCode}}
                    >
                        {quadrant.title}
                    </button>
                </div>
            )
        });
    };

    return (
        <nav className="quadrant-navbar">
            <div className="row">
                {/* <div className="col-12">
                    <div className="d-flex justify-content-between"> */}

                        <QuadrantItems />
                    
                    {/* </div>
                </div> */}
            </div>
        </nav>
    )
};