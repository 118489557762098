import React, { createRef, useState, useEffect } from "react";
import "./Form.scss"
import dataHandler from "../../api/dataHandler";
import UploadService from "../../api/uploadService";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

export default function FormWithSelectAndUpload({item, collection, getAllData, setMessageState, setHasErrorState, setErrorMessageState, setCurrentCriterionState, hasSortingNumber, selectArray, criteriaCheckBox, templatesCheckBox, criteria, cleanHook}) {
    // const [hasExample, setHasExampleState] = useState(false);
    const [currentCriteriumLevelFilter, setCurrentCriteriumLevelFilterState] = useState("all");
    const [currentCriteriumThemeFilter, setCurrentCriteriumThemeFilterState] = useState("all");
    const [currentFilteredCriteria, setCurrentFilteredCriteriaState] = useState(null);
    const [arrayOfSelectedCriteria, setArrayOfSelectedCriteriaState] = useState(false);
    const [arrayOfSelectedTemplates, setArrayOfSelectedTemplatesState] = useState(false);
    const uploadService = new UploadService();
    const form = createRef();

    useEffect(() => {
        item === "new" ? setArrayOfSelectedTemplatesState(false) : setArrayOfSelectedTemplatesState(item.templates);
    }, [item]);

    useEffect(() => {
        item === "new" ? setArrayOfSelectedCriteriaState(false) : setArrayOfSelectedCriteriaState(item.outgoingCriteria);
    }, [item]);

    useEffect(() => {
        let filteredCriteria = criteria;
        if (currentCriteriumThemeFilter !== "all") {
            filteredCriteria = filteredCriteria.filter(criterion => criterion.theme._id === currentCriteriumThemeFilter);

        }
        if (currentCriteriumLevelFilter !== "all") {
            filteredCriteria = filteredCriteria.filter(criterion => criterion.level._id === currentCriteriumLevelFilter);
        }
        filteredCriteria = filteredCriteria.filter(criterion => criterion._id !== item._id);
        setCurrentFilteredCriteriaState(filteredCriteria);
    }, [criteria, item, currentCriteriumLevelFilter, currentCriteriumThemeFilter]);

    const submitHandler = async e => {
        e.preventDefault();
        setMessageState(false);
        setHasErrorState(false);
        setErrorMessageState(false);

        const data = new FormData(form.current);

        // if (hasExample) {
            if (item !== "new" && item.examples[0]) {
                try {
                    data.append("example[_id]", item.examples[0]._id);
                    await dataHandler.post("/examples/update", data);
                    // data.append("exampleId", example.data._id);
                } catch (err) {
                    // setErrorMessageState(err.message);
                    // setHasErrorState(true);
                }
            } else {
                try {
                    let example = await dataHandler.post("/examples/create", data);
                    data.append("exampleId", example.data._id);
                } catch (err) {
                    // setErrorMessageState(err.message);
                    // setHasErrorState(true);
                }
            }
        // }
    
        if (item === "new") {
            
            try {
                await uploadService.createCriterion(data);
                setMessageState("Item successfully created.")
                getAllData();
                setCurrentCriterionState(null);
            } catch (err) {
                setErrorMessageState(err.response ? err.response.data.message : err.message);
                setHasErrorState(true);
            }

        } else {
            data.append("item__id", item._id);

            try {
                await uploadService.updateCriterion(data);
                setMessageState("Item successfully updated.")
                getAllData();
                setCurrentCriterionState(null);
            } catch (err) {
                setErrorMessageState(err.response ? err.response.data.message : err.message);
                setHasErrorState(true);
            }
        }
      };

    const selectInputs = () => {
        if (selectArray == null) {
            return false;
        } else {
            return selectArray.map((select, key) => {
                let selectName = select.name.toLowerCase();
                return (
                    <div key={key} className="form-field">
                        <select defaultValue={item[selectName] ? item[selectName]._id : ""} className="form-control" id={selectName} name={"select["+selectName+"]"} key={key}>
                            {
                                select.options.map((option, key) => {
                                    return <option value={option._id} key={key}>{option.title}</option>
                                })
                            }
                        </select>
                        <label htmlFor={select.name}>{select.name}</label>
                    </div>
                )
            });
        }
    };

    const filterInputHandler = async e => {
        e.preventDefault();
        let currentFilter = e.currentTarget;

        if (currentFilter.name === "levelSelect") {
            setCurrentCriteriumLevelFilterState(currentFilter.value);
        } else setCurrentCriteriumThemeFilterState(currentFilter.value);
    };

    const criterionInputHandler = e => {
        let val = e.currentTarget.value;
        if (val === "default") return;

        let selectedCriterion = criteria.find(criterion => criterion._id === val);
        let criteriaArray = [];
        arrayOfSelectedCriteria ? criteriaArray = [...arrayOfSelectedCriteria] : criteriaArray = [];
        let selectedCriteriaIds = [];

        criteriaArray.forEach(value => {
            selectedCriteriaIds.push(value._id);
        });

        e.currentTarget.value = "default"; //Reset select value to default so onInput is triggered on next input

        if (selectedCriteriaIds.includes(selectedCriterion._id)) return; //Prevents duplicates
        criteriaArray.push(selectedCriterion);
        setArrayOfSelectedCriteriaState(criteriaArray);
    };

    const templateInputHandler = e => {
        let val = e.currentTarget.value;
        if (val === "default") return;

        let selectedTemplate = templatesCheckBox.options.find(template => template._id === val);
        let templatesArray = [];
        arrayOfSelectedTemplates ? templatesArray = [...arrayOfSelectedTemplates] : templatesArray = [];
        let selectedTemplatesIds = [];

        templatesArray.forEach(value => {
            selectedTemplatesIds.push(value._id);
        });

        e.currentTarget.value = "default"; //Reset select value to default so onInput is triggered on next input

        if (selectedTemplatesIds.includes(selectedTemplate._id)) return; //Prevents duplicates
        templatesArray.push(selectedTemplate);
        setArrayOfSelectedTemplatesState(templatesArray);
    };

    const criteriaCheckBoxInputs = () => {
        if (criteriaCheckBox === null) {
            return false;
        } else {
            return (
                <>
                    <div className="d-flex align-items-center mt-3">
                        <span className="mb-0 form-title d-inline-block">Following {criteriaCheckBox.name}</span>
                    </div>
                    <span className="mb-0 d-block">Filter:</span>
                        {
                            selectArray.map((select, key) => {
                                let selectName = select.name.toLowerCase();
                                return (
                                    <div key={key} className="mt-2">
                                        <select defaultValue={selectName === "level" ? currentCriteriumLevelFilter : currentCriteriumThemeFilter} onChange={(e) => {filterInputHandler(e)}} className="form-control" id={selectName} name={selectName+"Select"} key={key}>
                                            <option value="all">All</option>
                                            {
                                                select.options.map((option, key) => {
                                                    return <option value={option._id} key={key}>{option.title}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                )
                            })
                        }
                        {
                            currentFilteredCriteria && currentFilteredCriteria.length > 0
                                ?   <div className="mt-2">
                                        <select defaultValue="default" onInput={(e) => criterionInputHandler(e)} className="form-control" id={"criterion"} name={"criterionSelect"}>
                                            <option disabled value="default">Select criterion</option>
                                            {
                                                currentFilteredCriteria.map((option, key) => {
                                                    return <option value={option._id} key={key}>{option.title}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                :   ""
                        }
                    <div>
                        {   
                            arrayOfSelectedCriteria
                                ?   arrayOfSelectedCriteria.map((option, key) => {
                                        return (
                                            <div key={key}>
                                                <input defaultChecked type="checkbox" id={option._id} name={criteriaCheckBox.name+"[]"} value={option._id} key={key}/>
                                                <label htmlFor={option._id}>{option.title}, {option.level.title}, {option.theme.title}</label>
                                            </div>
                                        )
                                    })
                                :   ""
                        }
                    </div>
                </>
            )
        }
    };

    const templatesCheckBoxInputs = () => {

        if (templatesCheckBox === null) {
            return false;
        } else {
            templatesCheckBox.options = templatesCheckBox.options.filter(option => option._id !== item._id);
            let templateIds = [];
            
            if (templatesCheckBox.options.length === 0) {
                return (
                    <>
                        <p className="mt-3 mb-0 form-title">Select {templatesCheckBox.name}</p>
                        <span className="d-inline-block alert alert-primary mb-3">No templates available for selection.</span>
                    </>
                )
            }
            

            if (item !== "new" && item.templates !== null) {
                item.templates.forEach(value => {
                    templateIds.push(value._id);
                });
            }

            let selectName = templatesCheckBox.name.toLowerCase();
            return (
                <div>
                    <p className="mt-3 mb-0 form-title">Select {templatesCheckBox.name}</p>
                    <div className="mt-2">
                        <select defaultValue="default" onChange={(e) => {templateInputHandler(e)}} className="form-control" id={selectName} name={selectName+"Select"}>
                            <option disabled value="default" className="disabled">Select template</option>
                                {   
                                    templatesCheckBox.options.map((option, key) => {
                                        return <option value={option._id} key={key}>{option.title}</option>
                                    })
                                }
                        </select>
                    </div>
                    {   
                        arrayOfSelectedTemplates
                            ?   arrayOfSelectedTemplates.map((option, key) => {
                                    return (
                                        <div key={key}>
                                            <input defaultChecked type="checkbox" id={option._id} name={templatesCheckBox.name+"[]"} value={option._id} key={key}/>
                                            <label htmlFor={option._id}>{option.title}</label>
                                            <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_API_BASE_URL}/downloads?fileName=${option.file}`}><FontAwesomeIcon size="sm" icon={faExternalLinkAlt} className="ml-2" /></a>
                                        </div>
                                    )
                                })
                            :   ""
                    }
                </div>
            )
        }
    };

    const handleDelete = async criterion => {
        try {
            await dataHandler.post("/criteria/delete", {item__id: item._id});
            setMessageState("Item successfully deleted.");
            getAllData();
            setCurrentCriterionState(null);
        } catch (err) {
            setErrorMessageState(err.message);
            setHasErrorState(true);
        }
    };
     
    return (
        <form onSubmit={submitHandler} ref={form} encType="multipart/form-data">
            <div className="form-field">
                <input id="title" className="form-control" name="title" placeholder="Title" defaultValue={item.title ? item.title : ""} />
                <label htmlFor="title">Title</label>
            </div>
            <div className="form-field">
                <textarea id="text" className="form-control" name="text" placeholder="Text" defaultValue={item.text ? item.text : ""}></textarea>
                <label htmlFor="text">Text</label>
            </div>

            {
                hasSortingNumber
                    ?   <div className="form-field">
                            <input step="100" type="number" id="sortingNumber" className="form-control" name="sortingNumber" placeholder="SortingNumber" defaultValue={item.sortingNumber ? item.sortingNumber : "Sorting number"} />
                            <label htmlFor="sortingNumber">Sorting number</label>
                        </div>
                    :   ""
            }

            {
                selectInputs()
            }

            {
                criteriaCheckBoxInputs()
            }

            {
                templatesCheckBoxInputs()
            }

            <p className="mt-2 mb-0 form-title">Example</p>

            <div className="form-field">
                <textarea defaultValue={item !== "new" && item.examples[0] ? item.examples[0].text : ""} id="exampleText" className="form-control" name="example[text]" placeholder="Text" ></textarea>
                <label htmlFor="exampleText">Text</label>
            </div>

            <div className="d-flex justify-content-between mt-4">
                <button className="btn btn-success" type="submit">Save</button>
                <button className="btn btn-dark" type="button" onClick={() => {cleanHook()}}>Close</button>
                {
                    item === "new"
                        ?   ""
                        :   <div>
                                <button className="btn btn-danger mr-3" type="button" onClick={() => {handleDelete(item)}}>Remove</button>
                            </div>
                }
            </div>
    </form>
    );
}