import React from "react";
import { useForm } from "react-hook-form";
import "./Form.scss";
import dataHandler from "../../api/dataHandler";

export default function CategoryForm({getAllData, setMessageState, setHasErrorState, setErrorMessageState}) {

    const { register, handleSubmit } = useForm();

    const onSubmit = async data => {
        setMessageState(false);
        setHasErrorState(false);
        setErrorMessageState(false);

        try {
            dataHandler.post("/categories/create", data);
            setMessageState("Item successfully created.");
            getAllData();
        } catch (err) {
            setErrorMessageState(err.message);
            setHasErrorState(true);
        }

    };
     
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-field">
            <input id="categoryTitle" className="form-control" name="categoryTitle" ref={register} placeholder="Title" />
            <label htmlFor="categoryTitle">Title</label>
        </div>
        <button className="btn btn-success mt-2" type="submit">Add</button>
      </form>
    );
}