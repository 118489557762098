import React, { useState, useEffect } from "react";
import dataHandler from "../../api/dataHandler";

import BasicListItem from "../../components/CMS/BasicListItem";
import FormWithSelect from "../../components/Form/FormWithSelect";
import Alert from 'react-bootstrap/Alert';
import Fade from 'react-reveal/Fade';

export default function ThemesList({themes, quadrants, getAllData}) {
    const [currentTheme, setCurrentThemeState] = useState(null);
    const [currentQuadrant, setCurrentQuadrantState] = useState("all");
    const [filteredThemes, setFilteredThemesState] = useState(null);
    const [message, setMessageState] = useState(false);
    const [hasError, setHasErrorState] = useState(false);
    const [errorMessage, setErrorMessageState] = useState(false);

    const compareThemesBySortingnumber = (a, b) => {
        if ( a.sortingNumber < b.sortingNumber ){
            return -1;
        }
        if ( a.sortingNumber > b.sortingNumber ){
            return 1;
        }
        return 0;
    };

    useEffect(() => {
        let themesArray = themes;

        if (currentQuadrant !== "all") {
            themesArray = themesArray.filter(theme => theme.quadrant._id === currentQuadrant).sort(compareThemesBySortingnumber);
        }
        setFilteredThemesState(themesArray);
    }, [themes, currentQuadrant])

    const cleanHook = () => {
        setCurrentThemeState(null);
        setMessageState(false);
        setHasErrorState(false);
        setErrorMessageState(false);
    };

    useEffect(() => {
        if (currentTheme !== null) {
            setMessageState(false);
            setHasErrorState(false);
            setErrorMessageState(false);
        }
    }, [currentTheme]);

    const ListOfThemes = () => {
        if (filteredThemes == null) {
            return false;
        } else if (filteredThemes.length === 0) {
            return (
                <Alert className="mt-3" variant="primary">No items yet.</Alert>
            );
        } else {
            return filteredThemes.map((theme, key) => {
                return (
                    <BasicListItem item={theme} setCurrentItemState={setCurrentThemeState} key={key} />
                )
            });
        }
    };

    const handleDelete = async theme => {
        try {
            await dataHandler.post("/themes/delete", { item__id: currentTheme._id });
            setMessageState("Item successfully deleted.")
            getAllData();
            setCurrentThemeState(null);
        } catch (err) {
            setErrorMessageState(err.message);
            setHasErrorState(true);
        }
    };

    const filterInputHandler = async e => {
        e.preventDefault();

        setCurrentQuadrantState(e.currentTarget.value);
    };
    
    if (currentTheme === null) {
        return (
            <>
                <Fade>
                    <div className="row d-flex align-items-center">
                        <div className="col">
                            <h1>Themes</h1>
                        </div>

                        <div className="col-12 col-lg-3">
                            <div className="d-flex my-lg-0 my-2">
                                {
                                    quadrants.length > 0
                                        ?   <select defaultValue={currentQuadrant} onInput={(e) => filterInputHandler(e)} className="form-control" id="theme" name={"quadrantSelect"}>
                                                <option value="all">All</option>
                                                {
                                                    quadrants.map((option, key) => {
                                                        return <option value={option._id} key={key}>{option.title}</option>
                                                    })
                                                }
                                            </select>
                                        :   ""
                                }
                            </div>
                        </div>
                            {
                                message 
                                    ? <Alert className="mb-0" variant="success" dismissible onClose={() => setMessageState(false)}>{message}</Alert> 
                                    : ""
                            }

                            {
                                hasError 
                                    ? <Alert className="mb-0" variant="danger" dismissible onClose={() => setHasErrorState(false)}>{errorMessage}</Alert> 
                                    : ""
                            }

                        <div className="col d-flex justify-content-start justify-content-lg-end">
                            <button className="btn btn-primary" onClick={() => {setCurrentThemeState("new")}}>Add new</button>
                        </div>
                    </div>
                </Fade>
                {ListOfThemes ? <ListOfThemes/> : ''}
            </>
        )
    } else {
        return (
            <div className="card mb-3">
                <div className="card-body">
                    <FormWithSelect 
                        item={currentTheme} 
                        collection="themes" 
                        getAllData={getAllData} 
                        setMessageState={setMessageState} 
                        setHasErrorState={setHasErrorState} 
                        setErrorMessageState={setErrorMessageState} 
                        hasSortingNumber={true}
                        hasDescription={false}
                        selectArray={[{name: 'Quadrant', options: quadrants}]}
                        setCurrentItemState={setCurrentThemeState}
                        handleDelete={handleDelete}
                        cleanHook={cleanHook}
                    />
                    <div className="d-flex justify-content-between">
                        {
                            message 
                                ? <Alert className="mt-3" variant="success" dismissible onClose={() => setMessageState(false)}>{message}</Alert> 
                                : ""
                        }

                        {
                            hasError 
                                ? <Alert className="mt-3" variant="danger" dismissible onClose={() => setHasErrorState(false)}>{errorMessage}</Alert> 
                                : ""
                        }
                    </div>
                </div>
            </div>
        )
    }
};