import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Fade from 'react-reveal/Fade';

import LevelNavBar from "../../components/Frontend/LevelView/LevelNavbar.js";
import QuadrantNavBar from "../../components/Frontend/LevelView/QuadrantNavbar.js";
import ThemeList from "../../components/Frontend/LevelView/ThemeList";

export default function Level({levels, themes, quadrants, definitions}) {
    const [currentLevel, setCurrentLevelState] = useState(levels[0]);//First level is selected by default
    const [executeCriterionScroll, setExecuteCriterionScrollState] = useState({execute: false, criterion: null});
    const [executeThemeScroll, setExecuteThemeScrollState] = useState({execute: false, theme: null});
    const [criterionRefsArray, setCriterionRefsArrayState] = useState(false);
    const [themeRefsArray, setThemeRefsArrayState] = useState(false);
    
    const location = useLocation();

    useEffect(() => {
        const createThemeRefs = () => {
            let arrayOfThemeIds = [];

            quadrants.forEach(quadrant => {
                quadrant.themes.forEach(theme => {
                    arrayOfThemeIds.push(theme._id);
                });
            });
    
            let themeRefs = arrayOfThemeIds.reduce((acc, value) => {
                acc[value] = React.createRef();
                return acc;
            }, {});
            
            return themeRefs;
        };
    
        let array = createThemeRefs();
        setThemeRefsArrayState(array);
    }, [quadrants]);

    useEffect(() => {
        const scrollToTheme = theme => {
            let el = themeRefsArray[theme._id].current;
            if (el === null) {
                window.scrollTo(0,0);
                return;
            };
            let top = el.offsetTop - 80;//Align clicked theme to top

            window.scrollTo({
                top: top,
                behavior: "smooth"//Animation doesn't work in safari
            });

            setExecuteThemeScrollState({execute: false, theme: null});
        };

        if (executeThemeScroll.execute === true) {
            scrollToTheme(executeThemeScroll.theme);
        }
    }, [executeThemeScroll, themeRefsArray]);

    useEffect(() => {//Select level when switching from theme view to level view
        if (location.state !== null) {
            setCurrentLevelState(location.state.level);
            setExecuteThemeScrollState({execute: true, theme: location.state.theme});
        }
    }, [location]);

    useEffect(() => {
        const createCriterionRefs = () => {
            let arrayOfCriterionIds = [];

            quadrants.forEach(quadrant => {
                quadrant.themes.forEach(theme => {
                    theme.criteria.forEach(criterion => {
                        arrayOfCriterionIds.push(criterion._id);
                    });
                });
            });
    
            let criterionRefs = arrayOfCriterionIds.reduce((acc, value) => {
                acc[value] = React.createRef();
                return acc;
            }, {});
            
            return criterionRefs;
        };
    
        let array = createCriterionRefs();
        setCriterionRefsArrayState(array);
    }, [quadrants]);

    useEffect(() => {
        const scrollToCriterion = criterion => {
            let el = criterionRefsArray[criterion].current;
            let top = el.getBoundingClientRect().top + window.scrollY - 80;//Align clicked criterion to top

            window.scrollTo({
                top: top,
                behavior: "smooth"//Animation doesn't work in safari
            });

            setExecuteCriterionScrollState({execute: false, criterion: null});
        };

        if (executeCriterionScroll.execute === true) {
            scrollToCriterion(executeCriterionScroll.criterion);
        }
    }, [executeCriterionScroll, criterionRefsArray]);

    const quadrantRefs = quadrants.reduce((acc, value) => {
        acc[value._id] = React.createRef();
        return acc;
    }, {});

    const scrollToQuadrant = quadrant => {
        let el = quadrantRefs[quadrant].current;
        if (el === null) return;
        let elementTop = el.offsetTop - 80;

        window.scrollTo({
            top: elementTop,
            behavior: "smooth"//Animation doesn't work in safari
        });
    };

    return (
        <div className="level-view">
            <LevelNavBar 
                levels={levels}
                currentLevel={currentLevel}
                setCurrentLevelState={setCurrentLevelState}
            />

            <QuadrantNavBar quadrants={quadrants} scrollToQuadrant={scrollToQuadrant} />

            <Fade>
                <div>
                    <div>
                        <span className="h2">{currentLevel.title}</span>
                    </div>
                    <div className="mb-3">
                        <span className="-tg-subtitle">{currentLevel.description}</span>
                    </div>
                </div>
            </Fade>
            
            <div>
                <ThemeList
                    quadrants={quadrants}
                    currentLevel={currentLevel}
                    quadrantRefs={quadrantRefs}
                    criterionRefs={criterionRefsArray}
                    themeRefs={themeRefsArray}
                    definitions={definitions}
                    setCurrentLevelState={setCurrentLevelState}
                    setExecuteCriterionScrollState={setExecuteCriterionScrollState}
                />
            </div>
        </div>
    )
};
