import React, { createRef, useState } from "react";
import "./Form.scss"
import UploadService from "../../api/uploadService";

export default function FormWithSelectAndUpload({item, getAllData, setMessageState, setHasErrorState, setErrorMessageState, setCurrentTemplateState, hasSortingNumber, handleDelete, cleanHook}) {
    const [hasFile, setHasFileState] = useState(false);
    const uploadService = new UploadService();
    const form = createRef();

    const submitHandler = async e => {
        e.preventDefault();
        setMessageState(false);
        setHasErrorState(false);
        setErrorMessageState(false);

        const data = new FormData(form.current);
        
        if (item === "new" && !hasFile) {
            setHasErrorState(true);
            setErrorMessageState("File is required.");
        } else if (item === "new" && hasFile) {
            try {
                await uploadService.uploadTemplate(data);
                setMessageState("Item successfully created.")
                getAllData();
                setCurrentTemplateState(null);
            } catch (err) {
                setErrorMessageState(err.message);
                setHasErrorState(true);
            }
        } else {
            data.append("template[item__id]", item._id);

            try {
                await uploadService.updateTemplate(data);
                setMessageState("Item successfully updated.")
                getAllData();
                setCurrentTemplateState(null);
            } catch (err) {
                setErrorMessageState(err.message);
                setHasErrorState(true);
            }
        }
    };

    const templateFile = () => {
        if (item !== "new") {
            return (
                <div className="row">
                    <div className="my-3 col-12 col-md-6">
                        <div>
                            <div className="d-flex justify-content-between mt-1">
                                <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_API_BASE_URL}/downloads?fileName=${item.file}`}>{item.file}</a>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    };
     
    return (
      <form onSubmit={submitHandler} ref={form} encType="multipart/form-data">

        {
            hasSortingNumber
                ?   <div className="form-field">
                        <input step="100" type="number" id="sortingNumber" className="form-control" name="sortingNumber" placeholder="SortingNumber" defaultValue={item.sortingNumber ? item.sortingNumber : "Sorting number"} />
                        <label htmlFor="sortingNumber">Sorting number</label>
                    </div>
                :   ""
        }

        <div className='form-field'>
            <input className="form-control" id="templateTitle" name="template[title]" type="text" placeholder="Title" defaultValue={item.title ? item.title : ""} />
            <label htmlFor="templateTitle">Title</label>
        </div>
        <div className="form-field">
            <input onChange={() => {setHasFileState(true)}} id="templateFile" name="template[file]" type="file" />
            <label htmlFor="templateFile">File</label>
        </div>

        {
            templateFile()
        }

        <div className="d-flex justify-content-between">
            <button className="btn btn-success" type="submit">Save</button>
            <button className="btn btn-dark" type="button" onClick={() => {cleanHook()}}>Close</button>
            {
                item === "new"
                    ?   ""
                    :   <div>
                            <button className="btn btn-danger mr-3" type="button" onClick={() => {handleDelete(item)}}>Remove</button>
                        </div>
            }
        </div>
      </form>
    );
}