import React, { useState, useEffect } from "react";
import axios from "axios";

import BasicListItem from "../../components/CMS/BasicListItem";
import SimpleForm from "../../components/Form/SimpleForm";
import Alert from 'react-bootstrap/Alert';
import Fade from 'react-reveal/Fade';

export default function DefinitionsList({definitions, getAllData}) {
    const [currentDefinition, setCurrentDefinitionState] = useState(null);
    const [message, setMessageState] = useState(false);
    const [hasError, setHasErrorState] = useState(false);
    const [errorMessage, setErrorMessageState] = useState(false);

    const cleanHook = () => {
        setCurrentDefinitionState(null);
        setMessageState(false);
        setHasErrorState(false);
        setErrorMessageState(false);
    };

    useEffect(() => {
        if (currentDefinition !== null) {
            setMessageState(false);
            setHasErrorState(false);
            setErrorMessageState(false);
        }
    }, [currentDefinition]);

    const ListOfDefinitions = () => {
        if (definitions == null) {
            return false;
        } else if (definitions.length === 0) {
            return (
                <Alert className="mt-3" variant="primary">No items yet.</Alert>
            );
        } else {
            return definitions.map((definition, key) => {
                return (
                    <BasicListItem item={definition} setCurrentItemState={setCurrentDefinitionState} key={key} />
                )
            });
        }
    };

    const handleDelete = async definition => {
        try {
            await axios({
                url: `${process.env.REACT_APP_API_CMS_URL}/definitions/delete`,
                method: "POST",
                withCredentials: true,
                data: {
                    item__id: currentDefinition._id
                }
            });
            setMessageState("Item successfully deleted.")
            getAllData();
            setCurrentDefinitionState(null);
        } catch (err) {
            setErrorMessageState(err.message);
            setHasErrorState(true);
        }
    };
    
    if (currentDefinition == null) {
        return (
            <>
                <Fade>
                    <div className="d-flex justify-content-between">
                        <h1>Definitions</h1>

                        {
                            message 
                                ? <Alert className="mb-0" variant="success" dismissible onClose={() => setMessageState(false)}>{message}</Alert> 
                                : ""
                        }

                        {
                            hasError 
                                ? <Alert className="mb-0" variant="danger" dismissible onClose={() => setHasErrorState(false)}>{errorMessage}</Alert> 
                                : ""
                        }

                        <button className="btn btn-primary" onClick={() => {setCurrentDefinitionState("new")}}>Add new</button>
                    </div>
                </Fade>
                {ListOfDefinitions ? <ListOfDefinitions/> : ''}
            </>
        )
    } else {
        return (
            <div className="card mb-3">
                <div className="card-body">
                    <SimpleForm
                        item={currentDefinition} 
                        collection="definitions" 
                        getAllData={getAllData} 
                        setMessageState={setMessageState} 
                        setHasErrorState={setHasErrorState} 
                        setErrorMessageState={setErrorMessageState} 
                        hasSortingNumber={false}
                        setCurrentItemState={setCurrentDefinitionState}
                        handleDelete={handleDelete}
                        cleanHook={cleanHook}
                    />
                    <div className="d-flex justify-content-between">
                        {
                            message 
                                ? <Alert className="mt-3" variant="success" dismissible onClose={() => setMessageState(false)}>{message}</Alert> 
                                : ""
                        }
                        {
                            hasError 
                                ? <Alert className="mt-3" variant="danger" dismissible onClose={() => setHasErrorState(false)}>{errorMessage}</Alert> 
                                : ""
                        }                        
                    </div>
                </div>
            </div>
        )
    }
};