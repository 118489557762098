import React, { useState, useEffect } from "react";

import BasicListItem from "../../components/CMS/BasicListItem";
import SimpleForm from "../../components/Form/SimpleForm";
import Alert from 'react-bootstrap/Alert';
import Fade from 'react-reveal/Fade';

export default function QuadrantsList({quadrants, getAllData}) {
    const [currentQuadrant, setCurrentQuadrantState] = useState(null);
    const [message, setMessageState] = useState(false);
    const [hasError, setHasErrorState] = useState(false);
    const [errorMessage, setErrorMessageState] = useState(false);

    const cleanHook = () => {
        setCurrentQuadrantState(null);
        setMessageState(false);
        setHasErrorState(false);
        setErrorMessageState(false);
    };

    useEffect(() => {
        if (currentQuadrant !== null) {
            setMessageState(false);
            setHasErrorState(false);
            setErrorMessageState(false);
        }
    }, [currentQuadrant]);

    const ListOfQuadrants = () => {
        if (quadrants == null) {
            return false;
        } else if (quadrants.length === 0) {
            return (
                <Alert className="mt-3" variant="primary">No items yet.</Alert>
            );
        } else {
            return quadrants.map((quadrant, key) => {
                return (
                    <BasicListItem item={quadrant} setCurrentItemState={setCurrentQuadrantState} key={key} />
                )
            });
        }
    };
    
    if (currentQuadrant == null) {
        return (
            <>
                <Fade>
                    <div className="d-flex justify-content-between">
                        <h1>Quadrants</h1>
                        
                        {
                            message 
                                ? <Alert className="mb-0" variant="success" dismissible onClose={() => setMessageState(false)}>{message}</Alert> 
                                : ""
                        }

                        {
                            hasError 
                                ? <Alert className="mb-0" variant="danger" dismissible onClose={() => setHasErrorState(false)}>{errorMessage}</Alert> 
                                : ""
                        }

                    </div>
                </Fade>
                {ListOfQuadrants ? <ListOfQuadrants/> : ''}
            </>
        )
    } else {
        return (
            <div className="card mb-3">
                <div className="card-body">                
                    <SimpleForm 
                        item={currentQuadrant} 
                        collection="quadrants" 
                        getAllData={getAllData} 
                        setMessageState={setMessageState} 
                        setHasErrorState={setHasErrorState} 
                        setErrorMessageState={setErrorMessageState} 
                        hasSortingNumber={true}
                        hasDescription={false}
                        setCurrentItemState={setCurrentQuadrantState}
                        handleDelete={false}
                        cleanHook={cleanHook}
                    />
                    <div className="d-flex justify-content-between">
                        {
                            message 
                                ? <Alert className="mt-3" variant="success" dismissible onClose={() => setMessageState(false)}>{message}</Alert> 
                                : ""
                        }

                        {
                            hasError 
                                ? <Alert className="mt-3" variant="danger" dismissible onClose={() => setHasErrorState(false)}>{errorMessage}</Alert> 
                                : ""
                        }
                    </div>
                </div>
            </div>
        ) 
    }
};