import axios from "axios";

export default class AuthService {
    constructor() {
        this.service = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            withCredentials: true,
        });
    }

    validateUser = async payload => {
        const { data } = await this.service.post("/authenticate", payload);
        return data;
    };

    validateToken = async payload => {
        const { data } = await this.service.post("/authenticate", payload);
        return data;
    };
}