import React, { useState, useEffect } from "react";

import BasicListItem from "../../components/CMS/BasicListItem";
import SimpleForm from "../../components/Form/SimpleForm";
import Alert from 'react-bootstrap/Alert';
import Fade from 'react-reveal/Fade';

export default function LevelsList({levels, getAllData}) {
    const [currentLevel, setCurrentLevelState] = useState(null);
    const [message, setMessageState] = useState(false);
    const [hasError, setHasErrorState] = useState(false);
    const [errorMessage, setErrorMessageState] = useState(false);

    const cleanHook = () => {
        setCurrentLevelState(null);
        setMessageState(false);
        setHasErrorState(false);
        setErrorMessageState(false);
    };

    useEffect(() => {
        if (currentLevel !== null) {
            setMessageState(false);
            setHasErrorState(false);
            setErrorMessageState(false);
        }
    }, [currentLevel]);

    // const handleDelete = async level => {
    //     try {
    //         await axios({
    //             url: `${process.env.REACT_APP_API_CMS_URL}/levels/delete`,
    //             method: "POST",
    //             withCredentials: true,
    //             data: {
    //                 levelId: level._id
    //             }
    //         });
    //         getAllData();
    //         setCurrentLevelState(null);
    //     } catch (err) {
    //         console.log(err);//TODOIMMENSE add feedback after user action like DELETE
    //     }
    // };

    const ListOfLevels = () => {
        if (levels == null) {
            return false;
        } else if (levels.length === 0) {
            return (
                <Alert className="mt-3" variant="primary">No items yet.</Alert>
            );
        } else {
            return levels.map((level, key) => {
                return (
                    <BasicListItem item={level} setCurrentItemState={setCurrentLevelState} key={key} />
                )
            })
        }
    };

    if (currentLevel == null) {
        return (
            <>
                <Fade>
                    <div className="d-flex justify-content-between">
                        <h1>Levels</h1>
                        
                        {
                            message 
                                ? <Alert className="mb-0" variant="success" dismissible onClose={() => setMessageState(false)}>{message}</Alert> 
                                : ""
                        }

                        {
                            hasError 
                                ? <Alert className="mb-0" variant="danger" dismissible onClose={() => setHasErrorState(false)}>{errorMessage}</Alert> 
                                : ""
                        }

                    </div>
                </Fade>
                {ListOfLevels ? <ListOfLevels/> : ''}
            </>
        )
    } else {
        return (
            <div className="card mb-3">
                <div className="card-body">
                    <SimpleForm 
                        item={currentLevel} 
                        collection="levels" 
                        getAllData={getAllData} 
                        setMessageState={setMessageState} 
                        setHasErrorState={setHasErrorState} 
                        setErrorMessageState={setErrorMessageState}
                        setCurrentItemState={setCurrentLevelState}
                        handleDelete={false}
                        cleanHook={cleanHook}
                    />
                    <div className="d-flex justify-content-between">
                        {
                            message 
                                ? <Alert className="mt-3" variant="success" dismissible onClose={() => setMessageState(false)}>{message}</Alert> 
                                : ""
                        }

                        {
                            hasError 
                                ? <Alert className="mt-3" variant="danger" dismissible onClose={() => setHasErrorState(false)}>{errorMessage}</Alert> 
                                : ""
                        }
                    </div>
                </div>
            </div>
        )
    }
};