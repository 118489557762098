import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import './App.scss';

import Frontend from "./Frontend.js";
import CMS from "./CMS.js";
import AdminRoute from "./components/AdminRoute.js";
import UserErrorPage from "./pages/user-error-page.js";
import Loader from "./components/Loader.js"

import AuthService from "./api/authService";

export default function App() {
  const [isLoading, setIsLoadingState] = useState(true);
  const [user, setUserState] = useState(null);

  // // FOR DEVELOPMENT PURPOSES ONLY
  // useEffect(() => {
  //   setUserState({
  //     _id : "5c2e1c59d96421252d934ce4",
  //     firstName : "Steven",
  //     lastName : "Lindelauff",
  //     profileImage : "https://.../profile-image.jpeg",
  //     isAdmin : true
  //   })

  //   setIsLoadingState(false);
  // }, []);


  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get("token");
    const authService = new AuthService();
    const validateToken = async () => {
      try {
        let user = await authService.validateToken({token});
        setUserState(user);
        setIsLoadingState(false);
      } catch (err) {
        setUserState(false);
        setIsLoadingState(false);
      }
    };
    validateToken();
  }, []);

  if (isLoading) return <Loader />;
  if (!user) {
    return (
      <UserErrorPage />
    )
  } else {
    return (
      <div className="App">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-3">
  
              <Routes>
                <Route path="/*" element={<Frontend user={user} />}/>
                <AdminRoute user={user} path="/admin/*" element={CMS} />
              </Routes>
  
            </div>
          </div>
        </div>
      </div>
    );
  };
};
