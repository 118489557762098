import axios from "axios";

export default class UploadService {
    constructor() {
        this.service = axios.create({
            baseURL: process.env.REACT_APP_API_CMS_URL,
            withCredentials: true,
        });
    }

    uploadTemplate = async payload => {
        const { data } = await this.service.post("templates/create", payload);
        return data;
    };
    updateTemplate = async payload => {
        const { data } = await this.service.post("templates/update", payload);
        return data;
    };

    createCriterion = async payload => {
        const { data } = await this.service.post("criteria/create", payload);
        return data;
    };

    updateCriterion = async payload => {
        const { data } = await this.service.post("criteria/update", payload);
        return data;
    };

    uploadImage = async payload => {
        const { data } = await this.service.post("WYSIWYG/upload", payload);
        return data;
    };
}