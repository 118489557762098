import React from "react";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import CriterionListItem from "../CriterionListItem";

import dataProvider from "../../../api/dataProvider";

export default function ThemeListItem({thisRef, theme, currentLevel, quadrantColorCode, definitions, setCurrentLevelState, criterionRefs, setExecuteCriterionScrollState}) {
    const navigate = useNavigate();

    const arrowClickHandler = criterion => {
        setCurrentLevelState(criterion.level);
        setExecuteCriterionScrollState({execute: true, criterion: criterion._id});
    };

    const viewClickHandler = async theme => {
        try {
            await Promise.all([
                dataProvider.get(`/quadrant?_id=${theme.quadrant}`)
            ]).then(([quadrant]) => {
                navigate('/theme', {state: {theme: theme, quadrant: quadrant.data, level: currentLevel}});
            });
        } catch (err) {
            // setErrorMessageState(err.message);
        }
    };
    
    const ListOfCriteria = () => {
        let filteredCriteria = theme.criteria.filter(criterion => criterion.level._id === currentLevel._id);

        if (criterionRefs) {
            return filteredCriteria.map((criterion, key) => {
                return ( 
                    <CriterionListItem key={key} thisRef={criterionRefs[criterion._id]} criterion={criterion} definitions={definitions} setCurrentSelectionState={arrowClickHandler} />
                );
            });
        } else return false;
    };

    if (thisRef !== false) {
        return (
            <div className="row" ref={thisRef}>
                <div className="col">
                    <div className="card frontend-list-item mt-3" style={{borderLeftColor:quadrantColorCode}}>
                        <div className="card-body -no-padding">
                            <div className="row">
                                <div className="col-12 col-lg-3">
                                    <div className="p-4">
                                        <p className="-tg-small-title -bold -uppercase">{theme.title}</p>
                                        <span
                                            onClick={() => {viewClickHandler(theme)}}
                                            className="-tg-link -turq"
                                        >Theme view
                                            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-9">
    
                                    <ListOfCriteria></ListOfCriteria>
    
                                </div>
    
                            </div>                
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="row">
                <div className="col">
                    <div className="card frontend-list-item mt-3" style={{borderLeftColor:quadrantColorCode}}>
                        <div className="card-body -no-padding">
                            <div className="row">
                                <div className="col-12 col-lg-3">
                                    <div className="p-4">
                                        <p className="-tg-small-title -bold -uppercase">{theme.title}</p>
                                        <span
                                            onClick={() => {viewClickHandler(theme)}}
                                            className="-tg-link -turq"
                                        >Theme view
                                            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-9">
    
                                    <ListOfCriteria></ListOfCriteria>
    
                                </div>
    
                            </div>                
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};