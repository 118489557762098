import React, { useState, useEffect } from "react";

import WebtextListItem from "../../components/CMS/WebtextListItem";
import SimpleFormWithRichText from "../../components/Form/SimpleFormWithRichText";
import Alert from 'react-bootstrap/Alert';
import Fade from 'react-reveal/Fade';

export default function WebtextsList({webtexts, getAllData}) {
    const [currentWebtext, setCurrentWebtextState] = useState(null);
    const [message, setMessageState] = useState(false);
    const [hasError, setHasErrorState] = useState(false);
    const [errorMessage, setErrorMessageState] = useState(false);

    const cleanHook = () => {
        setCurrentWebtextState(null);
        setMessageState(false);
        setHasErrorState(false);
        setErrorMessageState(false);
    };

    useEffect(() => {
        if (currentWebtext !== null) {
            setMessageState(false);
            setHasErrorState(false);
            setErrorMessageState(false);
        }
    }, [currentWebtext]);

    const ListOfWebtexts = () => {
        if (webtexts == null) {
            return false;
        } else if (webtexts.length === 0) {
            return (
                <Alert className="mt-3" variant="primary">No items yet.</Alert>
            );
        } else {
            return webtexts.map((webtext, key) => {
                return (
                    <WebtextListItem webtext={webtext} setCurrentWebtextState={setCurrentWebtextState} key={key} />
                )
            });
        }
    };

    // const handleDelete = async webtext => {
    //     try {
    //         await axios({
    //             url: `${process.env.REACT_APP_API_CMS_URL}/webtexts/delete`,
    //             method: "POST",
    //             withCredentials: true,
    //             data: {
    //                 item__id: currentWebtext._id
    //             }
    //         });
    //         setMessageState("Item successfully deleted.")
    //         getAllData();
    //         setCurrentWebtextState(null);
    //     } catch (err) {
    //         setErrorMessageState(err.message);
    //         setHasErrorState(true);
    //     }
    // };

    if (currentWebtext == null) {
        return (
            <>
                <Fade>
                    <div className="d-flex justify-content-between">
                        <h1>Webtexts</h1>

                        {
                            message 
                                ? <Alert className="mb-0" variant="success" dismissible onClose={() => setMessageState(false)}>{message}</Alert> 
                                : ""
                        }

                        {
                            hasError 
                                ? <Alert className="mb-0" variant="danger" dismissible onClose={() => setHasErrorState(false)}>{errorMessage}</Alert> 
                                : ""
                        }

                        {/* <button className="btn btn-primary" onClick={() => {setCurrentWebtextState("new")}}>Add new</button> */}
                    </div>
                </Fade>
                {ListOfWebtexts ? <ListOfWebtexts/> : ''}
            </>
        )
    } else {
        return (
            <div className="card mb-3">
                <div className="card-body">
                    <SimpleFormWithRichText 
                        item={currentWebtext} 
                        collection="webtexts" 
                        getAllData={getAllData} 
                        setMessageState={setMessageState} 
                        setHasErrorState={setHasErrorState} 
                        setErrorMessageState={setErrorMessageState} 
                        hasSortingNumber={false}
                        setCurrentItemState={setCurrentWebtextState}
                        cleanHook={cleanHook}
                    />
                    <div className="d-flex justify-content-between">
                        {
                            message 
                                ? <Alert className="mt-3" variant="success" dismissible onClose={() => setMessageState(false)}>{message}</Alert> 
                                : ""
                        }

                        {
                            hasError 
                                ? <Alert className="mt-3" variant="danger" dismissible onClose={() => setHasErrorState(false)}>{errorMessage}</Alert> 
                                : ""
                        }                        
                    </div>
                </div>
            </div>
        )
    }
};