import React from "react";
import { Route, Navigate } from "react-router-dom";

export default function AdminRoute({ element: Component, user, ...rest }) {

    if (user.isAdmin) {
        return (
            <Route
                {...rest}
                element={
                    <Component user={user} {...rest} />
                }
            />
        )
    } else {
        return (
            <Navigate
                to="/"
            />        
        );
    }

};