import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import "../Navbar.scss";

export default function NavBar({routes}) {

    const RouteLinks = () => {
        return routes.map((route, key) => {
            return (
                <div className="navbar-item" key={key}>
                    <NavLink
                        to={route.path}
                        className="nav-link"
                        activeClassName="active"
                    >
                        <p>{route.name}</p>
                    </NavLink>
                </div>
            )
        });
    };

    return (
        <nav className="navbar -blue overflow-x-scroll">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-between">
                            <div className="navbar-item">
                                <NavLink
                                    to="/"
                                    className="nav-link home-link"
                                    end
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                    Tool homepage
                                </NavLink>
                            </div>
                            <RouteLinks></RouteLinks>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
};